<script setup>
import { useI18n } from "vue-i18n";
import { useNotification } from "@kyvg/vue3-notification";
import { useQueryClient } from "@tanstack/vue-query";

const { t } = useI18n();

const props = defineProps({
  wine: { required: true, type: Object },
  brand: { required: true, type: Object },
  source: { required: false, default: "unknown" },
  onStarted: { required: false, type: Function },
  onSettled: { required: false, type: Function },
});

const modalStore = useModalStore();

const isLoading = ref(false);

const emit = defineEmits();

const { mutate: unpublishWine } = useUnpublishWineMutation();

const { notify } = useNotification();
const queryClient = useQueryClient();

const { $intercom } = useNuxtApp();

onMounted(() => {
  $intercom.trackEvent("Label unpublish form opened", {
    name: props.wine.name,
    source: props.source,
  });
});

const onSubmit = async () => {
  isLoading.value = true;

  const wineId = props.wine.id;
  const brandId = props.brand.id;

  if (props.onStarted) {
    props.onStarted();
  }

  unpublishWine(
    { brandId, wineId },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries({
          queryKey: ["user"],
        });
        await queryClient.refetchQueries({
          queryKey: ["brands", brandId],
        });
        notify({
          type: "success",
          text: t("unpublish-wine.success", [props.wine.name]),
        });
        localStorage.setItem(
          `dismissedPublishNoticeFor${props.brand.id}`,
          false,
        );
        isLoading.value = false;
        modalStore.closeModal();

        if (props.onSettled) {
          props.onSettled();
        }
      },
      onError: () => {
        console.log(err);
        notify({
          type: "error",
          text: err.message,
        });
        isLoading.value = false;
        if (props.onSettled) {
          props.onSettled();
        }
      },
    },
  );
};
</script>

<template>
  <div class="flex flex-col gap-4">
    <DialogTitle> {{ $t("unpublish-wine.title") }} </DialogTitle>

    <Text>{{ $t("unpublish-wine.description") }}</Text>

    <Button
      variant="primary"
      :loading="!!isLoading"
      @click="onSubmit"
      class="mt-6"
      size="lg"
      >{{ $t("unpublish-wine.submit") }}</Button
    >
    <Button
      variant="secondary-outlined"
      size="lg"
      @click="modalStore.closeModal"
      >{{ $t("delete-label-modal.cancel") }}</Button
    >
    <Text variant="body_light_sm" class="text-center">
      {{ $t("unpublish-wine.re-publish") }}
    </Text>
  </div>
</template>
