const ingredientTypes = [
  "ingredientsRaw",
  "ingredientsEnrichments",
  "ingredientsPreservative",
  "ingredientsAcidity",
  "ingredientsStabilisers",
  "ingredientsPackagingGazes",
  "ingredientsLiqueur", // deprecated
  "ingredientsTirage",
  "ingredientsExpedition",
  "ingredientsOther",
  "ingredientsAllergens",
];

export const useIngredientData = () => {
  const { data, isFetched } = useIngredientsMapQuery();

  const { getIngredientTranslation, isIngredientTranslationsFetched } =
    useIngredientTranslation();

  const getECode = (id) => {
    if (!isFetched) {
      return "";
    }

    return data.value.codes[id];
  };

  const getIngredientLabel = (id, showECode = false) => {
    const name = getIngredientTranslation(id);

    if (!showECode) {
      return name;
    }

    const eCode = getECode(id);

    if (!eCode) {
      return name;
    }

    return `${name} (${eCode})`;
  };

  const getIngredientsByType = (showECode = false) => {
    const ingredientsByType = {};

    ingredientTypes.forEach((type) => {
      if (isIngredientTranslationsFetched.value) {
        ingredientsByType[type] = data.value[type].map((ingredient) => ({
          name: getIngredientLabel(ingredient, showECode),
          value: ingredient,
        }));
      } else {
        ingredientsByType[type] = [];
      }
    });

    return ingredientsByType;
  };

  return {
    isIngredientDataFetched: isFetched,
    getIngredientsByType,
    getIngredientLabel,
    getECode,
  };
};
