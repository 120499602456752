import { useMutation } from "@tanstack/vue-query";

const updateUserDetails = async (variables) => {
  return await fetchWithAuth("/api/currentUser/userDetails", {
    method: "POST",
    body: variables,
  });
};

export const useUpdateUserDetailsMutation = () => {
  return useMutation({
    mutationFn: updateUserDetails,
  });
};
