<script setup>
const contactUsUrl = usePublicSiteUrl("contact");

const modalStore = useModalStore();
</script>

<template>
  <notifications width="600px" position="top center">
    <template #body="props">
      <div class="pinotqr-notification" :class="props.item.type">
        <div class="icon">
          <font-awesome
            v-if="props.item.type === 'success'"
            icon="check"
            class="pinotqr-notification-icon"
          />
          <font-awesome v-else icon="xmark" class="pinotqr-notification-icon" />
        </div>
        <div v-html="props.item.text" />
      </div>
    </template>
  </notifications>
  <div class="content relative">
    <aside
      class="left-panel bg-primary hidden md:flex flex-col justify-between"
    >
      <div class="h-[28px] mb-8">
        <LogoFull />
      </div>

      <div>
        <Text color="inverted" variant="heading_1" as="h1">{{
          $t("login.welcome")
        }}</Text>
        <Text color="inverted" variant="body_light" class="mt-3">
          {{ $t("login.description") }}
        </Text>
        <div class="flex flex-col gap-3 mt-8">
          <div class="flex flex-row items-center">
            <font-awesome
              icon="check"
              class="mr-3 bg-primary-dark rounded-full p-[4px] aspect-square text-mono-white"
            />
            <Text color="inverted" variant="body_light">
              {{ $t("login.free") }}
            </Text>
          </div>
          <div class="flex flex-row items-center">
            <font-awesome
              icon="check"
              class="mr-3 bg-primary-dark rounded-full p-[4px] aspect-square text-mono-white"
            />
            <Text color="inverted" variant="body_light">
              {{ $t("login.no-credit-card") }}
            </Text>
          </div>
          <div class="flex flex-row items-center">
            <font-awesome
              icon="check"
              class="mr-3 bg-primary-dark rounded-full p-[4px] aspect-square text-mono-white"
            />
            <Text color="inverted" variant="body_light">
              {{ $t("login.cancel-anytime") }}
            </Text>
          </div>
        </div>
      </div>

      <div class="flex flex-row gap-6 mt-8">
        <TextLink
          to="https://help.pinotqr.com"
          :external="true"
          color="inverted"
          weight="light"
          size="sm"
          >{{ $t("login.help-and-support") }}</TextLink
        >

        <TextLink
          :to="contactUsUrl"
          :external="true"
          color="inverted"
          weight="light"
          size="sm"
          >{{ $t("login.contact-us") }}</TextLink
        >
      </div>
    </aside>
    <div class="m-auto md:mt-auto w-full h-screen flex">
      <div class="py-[100px] px-8 w-full h-full relative overflow-scroll flex">
        <div class="top-lang">
          <LanguageModalTrigger />
        </div>
        <router-view></router-view>
      </div>
    </div>
  </div>

  <ModalContainer />

  <ClientOnly>
    <cookie locale="en" />
  </ClientOnly>
</template>

<style scoped>
.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  height: 100%;

  .left-panel {
    width: 424px;
    height: 100vh;
    padding: 100px 60px 60px;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    align-self: stretch;
    overflow: auto;
  }
}

.top-lang {
  position: absolute;
  right: 20px;
  top: 20px;
}
</style>
