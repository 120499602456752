<script setup>
import { useI18n } from "vue-i18n";
import { useNotification } from "@kyvg/vue3-notification";
import { useQueryClient } from "@tanstack/vue-query";

const { t } = useI18n();

const props = defineProps({
  wine: { required: true, type: Object },
  source: { required: false, default: "unknown" },
});

const modalStore = useModalStore();

const isLoading = ref(false);

const emit = defineEmits();

const nuxtApp = useNuxtApp();
const queryClient = useQueryClient();

const { $intercom } = nuxtApp;

onMounted(() => {
  $intercom.trackEvent("Label delete form opened", {
    name: props.wine.name,
    source: props.source,
    published: props.wine.published,
  });
});

const { mutate: deleteWine } = useDeleteWineMutation();

const onSubmit = async () => {
  isLoading.value = true;

  const wineId = props.wine.id;
  const brandId = props.wine.brandId;

  const { notify } = useNotification();

  deleteWine(
    { brandId, wineId },
    {
      onSuccess: async (data) => {
        await Promise.allSettled([
          queryClient.refetchQueries(["brands"]),
          queryClient.refetchQueries({ queryKey: ["user"] }),
        ]);

        await navigateTo(nuxtApp.$localeRoute("/brands/" + brandId));
        modalStore.closeModal();
        notify({
          type: "success",
          text: `${data.name} has been deleted`,
        });
        isLoading.value = false;
      },
      onError: (err) => {
        console.log(err);
        notify({
          type: "error",
          text: err.message,
        });
        isLoading.value = false;
      },
    },
  );
};
</script>

<template>
  <div class="flex flex-col gap-4">
    <DialogTitle>
      {{ $t("delete-label-modal.title") }}
    </DialogTitle>

    <template v-if="!wine.published || wine.redirect_to || wine.locked_at">
      <Text v-html="$t('delete-label-modal.intro', [wine.name])"></Text>
      <Text> {{ $t("delete-label-modal.into-p2") }}</Text>

      <Button
        variant="destructive"
        :loading="!!isLoading"
        @click="onSubmit"
        class="mt-6"
        size="lg"
        >{{ $t("delete-label-modal.action") }}</Button
      >
      <Button
        variant="secondary-outlined"
        size="lg"
        @click="modalStore.closeModal"
        >{{ $t("delete-label-modal.cancel") }}</Button
      >
      <Text variant="body_light_sm" class="text-center">
        {{ $t("delete-label-modal.instant") }}
      </Text>
    </template>
    <template v-else>
      <Text> {{ $t("delete-label-modal.published-desc") }}</Text>

      <Button
        class="mt-6"
        size="lg"
        variant="secondary-outlined"
        @click="modalStore.closeModal"
        >{{ $t("delete-label-modal.close") }}</Button
      >
    </template>
  </div>
</template>
