import { useMutation } from "@tanstack/vue-query";

const redirectWine = async ({ wineId, brandId, ...wineData }) => {
  return await fetchWithAuth(
    `/api/currentUser/brands/${brandId}/wines/${wineId}/redirectWine`,
    {
      method: "POST",
      body: { ...wineData },
    }
  );
};

export const useRedirectWineMutation = () => {
  return useMutation({
    mutationFn: redirectWine,
  });
};
