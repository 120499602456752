import { type VariantProps, cva } from "class-variance-authority";

export { default as Alert } from "./Alert.vue";

export const alertVariants = cva(
  "flex w-full text-[13px] rounded-lg border [&>svg~*]:pl-7 [&>svg+div]:translate-y-[-3px] [&>svg]:absolute [&>svg]:left-4 [&>svg]:top-4 [&>svg]:text-foreground",
  {
    variants: {
      variant: {
        default: "text-foreground border-mono-black border-2",
        primary: "border-primary border-2",
        destructive: "border-destructive border-2",
      },
      fullwidth: {
        false: "w-fit",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  },
);

export const alertIconVariants = cva(
  "flex items-center justify-center aspect-square min-h-[60px] w-[60px] text-mono-white text-xl",
  {
    variants: {
      variant: {
        default: "border-mono-black border-2 bg-mono-black",
        primary: "border-primary border-2 bg-primary",
        destructive: "border-destructive border-2 bg-destructive",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  },
);

export type AlertVariants = VariantProps<typeof alertVariants>;
