<script setup>
import { SubscriptionService } from "~/service/SubscriptionService";

defineEmits(["confirm"]);
const props = defineProps({
  source: { type: String, default: "unknown" },
});

const portalUrlLoading = ref(false);

const { data } = useCurrentUserSubscriptionQuery();

const { $intercom } = useNuxtApp();

onMounted(() => {
  $intercom.trackEvent("Update plan modal viewed", { trigger: props.source });
});

async function openCustomerPortal() {
  if (portalUrlLoading.value) return;
  portalUrlLoading.value = true;
  const portalUrl = await SubscriptionService.getPortalUrl();
  window.location.href = portalUrl;
  portalUrlLoading.value = false;
}
</script>

<template>
  <div class="modal-title">
    {{ $t("update-subscription-modal.title") }}
  </div>

  <p class="modal-intro" v-if="data.allowUnlimitedDrafts">
    {{
      $t("update-subscription-modal.current-allowance-grandfathered", [
        data.planLimit,
      ])
    }}
  </p>

  <p class="modal-intro" v-else>
    {{ $t("update-subscription-modal.current-allowance", [data.planLimit]) }}
  </p>

  <div class="subscription-card-container">
    <SubscriptionCard />
  </div>

  <Button :loading="portalUrlLoading" @click="openCustomerPortal"
    >{{ $t("update-subscription-modal.upgrade-button") }}
  </Button>

  <Button variant="secondary-outlined" @click="$emit('confirm')">{{
    $t("update-subscription-modal.cancel-button")
  }}</Button>
</template>

<style scoped>
.subscription-card-container {
  margin: 5px 0 30px;
}

.update-subscription-modal .button:not(:last-of-type) {
  margin-bottom: 10px;
}
</style>
