import { useMutation } from "@tanstack/vue-query";

const updateBrand = async (brandData) => {
  console.log("Call create user!");
  const { brandId } = brandData;

  return await fetchWithAuth(`/api/currentUser/brands/${brandId}`, {
    method: "PATCH",
    body: { brandData },
  });
};

export const useUpdateBrandMutation = () => {
  return useMutation({
    mutationFn: updateBrand,
  });
};
