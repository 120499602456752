import { default as detailse8lH7cyYmiMeta } from "/Users/jonathancarr/pinotqr/app/pages/account/details.vue?macro=true";
import { default as indexoxz9rDBBNwMeta } from "/Users/jonathancarr/pinotqr/app/pages/account/index.vue?macro=true";
import { default as settingsLzhbpqeqi4Meta } from "/Users/jonathancarr/pinotqr/app/pages/account/settings.vue?macro=true";
import { default as actionMVVI6afYtgMeta } from "/Users/jonathancarr/pinotqr/app/pages/auth/action.vue?macro=true";
import { default as indexWQo95DQTUEMeta } from "/Users/jonathancarr/pinotqr/app/pages/brands/[id]/index.vue?macro=true";
import { default as _91wineid_93Giu5bKknnkMeta } from "/Users/jonathancarr/pinotqr/app/pages/brands/[id]/wine/[wineid].vue?macro=true";
import { default as index2hb1iHNl39Meta } from "/Users/jonathancarr/pinotqr/app/pages/index.vue?macro=true";
import { default as invoicesa1jkrBkqYcMeta } from "/Users/jonathancarr/pinotqr/app/pages/invoices.vue?macro=true";
import { default as loginiAIJE5ybquMeta } from "/Users/jonathancarr/pinotqr/app/pages/login.vue?macro=true";
import { default as register6vArz3OYmtMeta } from "/Users/jonathancarr/pinotqr/app/pages/register.vue?macro=true";
import { default as resetpasswordWJzeGeRBaCMeta } from "/Users/jonathancarr/pinotqr/app/pages/resetpassword.vue?macro=true";
import { default as verifyy3qT442Jn8Meta } from "/Users/jonathancarr/pinotqr/app/pages/verify.vue?macro=true";
export default [
  {
    name: "account-details___en",
    path: "/account/details",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/details.vue").then(m => m.default || m)
  },
  {
    name: "account-details___fr",
    path: "/fr/account/details",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/details.vue").then(m => m.default || m)
  },
  {
    name: "account-details___bg",
    path: "/bg/account/details",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/details.vue").then(m => m.default || m)
  },
  {
    name: "account-details___hr",
    path: "/hr/account/details",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/details.vue").then(m => m.default || m)
  },
  {
    name: "account-details___cs",
    path: "/cs/account/details",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/details.vue").then(m => m.default || m)
  },
  {
    name: "account-details___da",
    path: "/da/account/details",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/details.vue").then(m => m.default || m)
  },
  {
    name: "account-details___nl",
    path: "/nl/account/details",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/details.vue").then(m => m.default || m)
  },
  {
    name: "account-details___et",
    path: "/et/account/details",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/details.vue").then(m => m.default || m)
  },
  {
    name: "account-details___fi",
    path: "/fi/account/details",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/details.vue").then(m => m.default || m)
  },
  {
    name: "account-details___de",
    path: "/de/account/details",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/details.vue").then(m => m.default || m)
  },
  {
    name: "account-details___el",
    path: "/el/account/details",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/details.vue").then(m => m.default || m)
  },
  {
    name: "account-details___hu",
    path: "/hu/account/details",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/details.vue").then(m => m.default || m)
  },
  {
    name: "account-details___ga",
    path: "/ga/account/details",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/details.vue").then(m => m.default || m)
  },
  {
    name: "account-details___it",
    path: "/it/account/details",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/details.vue").then(m => m.default || m)
  },
  {
    name: "account-details___lv",
    path: "/lv/account/details",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/details.vue").then(m => m.default || m)
  },
  {
    name: "account-details___lt",
    path: "/lt/account/details",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/details.vue").then(m => m.default || m)
  },
  {
    name: "account-details___mt",
    path: "/mt/account/details",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/details.vue").then(m => m.default || m)
  },
  {
    name: "account-details___pl",
    path: "/pl/account/details",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/details.vue").then(m => m.default || m)
  },
  {
    name: "account-details___pt",
    path: "/pt/account/details",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/details.vue").then(m => m.default || m)
  },
  {
    name: "account-details___ro",
    path: "/ro/account/details",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/details.vue").then(m => m.default || m)
  },
  {
    name: "account-details___sk",
    path: "/sk/account/details",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/details.vue").then(m => m.default || m)
  },
  {
    name: "account-details___sl",
    path: "/sl/account/details",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/details.vue").then(m => m.default || m)
  },
  {
    name: "account-details___es",
    path: "/es/account/details",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/details.vue").then(m => m.default || m)
  },
  {
    name: "account-details___sv",
    path: "/sv/account/details",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/details.vue").then(m => m.default || m)
  },
  {
    name: "account___en",
    path: "/account",
    meta: indexoxz9rDBBNwMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___fr",
    path: "/fr/account",
    meta: indexoxz9rDBBNwMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___bg",
    path: "/bg/account",
    meta: indexoxz9rDBBNwMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___hr",
    path: "/hr/account",
    meta: indexoxz9rDBBNwMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___cs",
    path: "/cs/account",
    meta: indexoxz9rDBBNwMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___da",
    path: "/da/account",
    meta: indexoxz9rDBBNwMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___nl",
    path: "/nl/account",
    meta: indexoxz9rDBBNwMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___et",
    path: "/et/account",
    meta: indexoxz9rDBBNwMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___fi",
    path: "/fi/account",
    meta: indexoxz9rDBBNwMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___de",
    path: "/de/account",
    meta: indexoxz9rDBBNwMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___el",
    path: "/el/account",
    meta: indexoxz9rDBBNwMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___hu",
    path: "/hu/account",
    meta: indexoxz9rDBBNwMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___ga",
    path: "/ga/account",
    meta: indexoxz9rDBBNwMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___it",
    path: "/it/account",
    meta: indexoxz9rDBBNwMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___lv",
    path: "/lv/account",
    meta: indexoxz9rDBBNwMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___lt",
    path: "/lt/account",
    meta: indexoxz9rDBBNwMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___mt",
    path: "/mt/account",
    meta: indexoxz9rDBBNwMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___pl",
    path: "/pl/account",
    meta: indexoxz9rDBBNwMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___pt",
    path: "/pt/account",
    meta: indexoxz9rDBBNwMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___ro",
    path: "/ro/account",
    meta: indexoxz9rDBBNwMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___sk",
    path: "/sk/account",
    meta: indexoxz9rDBBNwMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___sl",
    path: "/sl/account",
    meta: indexoxz9rDBBNwMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___es",
    path: "/es/account",
    meta: indexoxz9rDBBNwMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___sv",
    path: "/sv/account",
    meta: indexoxz9rDBBNwMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-settings___en",
    path: "/account/settings",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/settings.vue").then(m => m.default || m)
  },
  {
    name: "account-settings___fr",
    path: "/fr/account/settings",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/settings.vue").then(m => m.default || m)
  },
  {
    name: "account-settings___bg",
    path: "/bg/account/settings",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/settings.vue").then(m => m.default || m)
  },
  {
    name: "account-settings___hr",
    path: "/hr/account/settings",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/settings.vue").then(m => m.default || m)
  },
  {
    name: "account-settings___cs",
    path: "/cs/account/settings",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/settings.vue").then(m => m.default || m)
  },
  {
    name: "account-settings___da",
    path: "/da/account/settings",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/settings.vue").then(m => m.default || m)
  },
  {
    name: "account-settings___nl",
    path: "/nl/account/settings",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/settings.vue").then(m => m.default || m)
  },
  {
    name: "account-settings___et",
    path: "/et/account/settings",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/settings.vue").then(m => m.default || m)
  },
  {
    name: "account-settings___fi",
    path: "/fi/account/settings",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/settings.vue").then(m => m.default || m)
  },
  {
    name: "account-settings___de",
    path: "/de/account/settings",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/settings.vue").then(m => m.default || m)
  },
  {
    name: "account-settings___el",
    path: "/el/account/settings",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/settings.vue").then(m => m.default || m)
  },
  {
    name: "account-settings___hu",
    path: "/hu/account/settings",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/settings.vue").then(m => m.default || m)
  },
  {
    name: "account-settings___ga",
    path: "/ga/account/settings",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/settings.vue").then(m => m.default || m)
  },
  {
    name: "account-settings___it",
    path: "/it/account/settings",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/settings.vue").then(m => m.default || m)
  },
  {
    name: "account-settings___lv",
    path: "/lv/account/settings",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/settings.vue").then(m => m.default || m)
  },
  {
    name: "account-settings___lt",
    path: "/lt/account/settings",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/settings.vue").then(m => m.default || m)
  },
  {
    name: "account-settings___mt",
    path: "/mt/account/settings",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/settings.vue").then(m => m.default || m)
  },
  {
    name: "account-settings___pl",
    path: "/pl/account/settings",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/settings.vue").then(m => m.default || m)
  },
  {
    name: "account-settings___pt",
    path: "/pt/account/settings",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/settings.vue").then(m => m.default || m)
  },
  {
    name: "account-settings___ro",
    path: "/ro/account/settings",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/settings.vue").then(m => m.default || m)
  },
  {
    name: "account-settings___sk",
    path: "/sk/account/settings",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/settings.vue").then(m => m.default || m)
  },
  {
    name: "account-settings___sl",
    path: "/sl/account/settings",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/settings.vue").then(m => m.default || m)
  },
  {
    name: "account-settings___es",
    path: "/es/account/settings",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/settings.vue").then(m => m.default || m)
  },
  {
    name: "account-settings___sv",
    path: "/sv/account/settings",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/account/settings.vue").then(m => m.default || m)
  },
  {
    name: "auth-action___en",
    path: "/auth/action",
    meta: actionMVVI6afYtgMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/auth/action.vue").then(m => m.default || m)
  },
  {
    name: "auth-action___fr",
    path: "/fr/auth/action",
    meta: actionMVVI6afYtgMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/auth/action.vue").then(m => m.default || m)
  },
  {
    name: "auth-action___bg",
    path: "/bg/auth/action",
    meta: actionMVVI6afYtgMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/auth/action.vue").then(m => m.default || m)
  },
  {
    name: "auth-action___hr",
    path: "/hr/auth/action",
    meta: actionMVVI6afYtgMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/auth/action.vue").then(m => m.default || m)
  },
  {
    name: "auth-action___cs",
    path: "/cs/auth/action",
    meta: actionMVVI6afYtgMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/auth/action.vue").then(m => m.default || m)
  },
  {
    name: "auth-action___da",
    path: "/da/auth/action",
    meta: actionMVVI6afYtgMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/auth/action.vue").then(m => m.default || m)
  },
  {
    name: "auth-action___nl",
    path: "/nl/auth/action",
    meta: actionMVVI6afYtgMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/auth/action.vue").then(m => m.default || m)
  },
  {
    name: "auth-action___et",
    path: "/et/auth/action",
    meta: actionMVVI6afYtgMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/auth/action.vue").then(m => m.default || m)
  },
  {
    name: "auth-action___fi",
    path: "/fi/auth/action",
    meta: actionMVVI6afYtgMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/auth/action.vue").then(m => m.default || m)
  },
  {
    name: "auth-action___de",
    path: "/de/auth/action",
    meta: actionMVVI6afYtgMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/auth/action.vue").then(m => m.default || m)
  },
  {
    name: "auth-action___el",
    path: "/el/auth/action",
    meta: actionMVVI6afYtgMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/auth/action.vue").then(m => m.default || m)
  },
  {
    name: "auth-action___hu",
    path: "/hu/auth/action",
    meta: actionMVVI6afYtgMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/auth/action.vue").then(m => m.default || m)
  },
  {
    name: "auth-action___ga",
    path: "/ga/auth/action",
    meta: actionMVVI6afYtgMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/auth/action.vue").then(m => m.default || m)
  },
  {
    name: "auth-action___it",
    path: "/it/auth/action",
    meta: actionMVVI6afYtgMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/auth/action.vue").then(m => m.default || m)
  },
  {
    name: "auth-action___lv",
    path: "/lv/auth/action",
    meta: actionMVVI6afYtgMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/auth/action.vue").then(m => m.default || m)
  },
  {
    name: "auth-action___lt",
    path: "/lt/auth/action",
    meta: actionMVVI6afYtgMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/auth/action.vue").then(m => m.default || m)
  },
  {
    name: "auth-action___mt",
    path: "/mt/auth/action",
    meta: actionMVVI6afYtgMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/auth/action.vue").then(m => m.default || m)
  },
  {
    name: "auth-action___pl",
    path: "/pl/auth/action",
    meta: actionMVVI6afYtgMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/auth/action.vue").then(m => m.default || m)
  },
  {
    name: "auth-action___pt",
    path: "/pt/auth/action",
    meta: actionMVVI6afYtgMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/auth/action.vue").then(m => m.default || m)
  },
  {
    name: "auth-action___ro",
    path: "/ro/auth/action",
    meta: actionMVVI6afYtgMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/auth/action.vue").then(m => m.default || m)
  },
  {
    name: "auth-action___sk",
    path: "/sk/auth/action",
    meta: actionMVVI6afYtgMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/auth/action.vue").then(m => m.default || m)
  },
  {
    name: "auth-action___sl",
    path: "/sl/auth/action",
    meta: actionMVVI6afYtgMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/auth/action.vue").then(m => m.default || m)
  },
  {
    name: "auth-action___es",
    path: "/es/auth/action",
    meta: actionMVVI6afYtgMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/auth/action.vue").then(m => m.default || m)
  },
  {
    name: "auth-action___sv",
    path: "/sv/auth/action",
    meta: actionMVVI6afYtgMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/auth/action.vue").then(m => m.default || m)
  },
  {
    name: "brands-id___en",
    path: "/brands/:id()",
    meta: indexWQo95DQTUEMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/brands/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "brands-id___fr",
    path: "/fr/brands/:id()",
    meta: indexWQo95DQTUEMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/brands/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "brands-id___bg",
    path: "/bg/brands/:id()",
    meta: indexWQo95DQTUEMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/brands/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "brands-id___hr",
    path: "/hr/brands/:id()",
    meta: indexWQo95DQTUEMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/brands/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "brands-id___cs",
    path: "/cs/brands/:id()",
    meta: indexWQo95DQTUEMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/brands/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "brands-id___da",
    path: "/da/brands/:id()",
    meta: indexWQo95DQTUEMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/brands/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "brands-id___nl",
    path: "/nl/brands/:id()",
    meta: indexWQo95DQTUEMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/brands/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "brands-id___et",
    path: "/et/brands/:id()",
    meta: indexWQo95DQTUEMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/brands/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "brands-id___fi",
    path: "/fi/brands/:id()",
    meta: indexWQo95DQTUEMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/brands/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "brands-id___de",
    path: "/de/brands/:id()",
    meta: indexWQo95DQTUEMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/brands/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "brands-id___el",
    path: "/el/brands/:id()",
    meta: indexWQo95DQTUEMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/brands/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "brands-id___hu",
    path: "/hu/brands/:id()",
    meta: indexWQo95DQTUEMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/brands/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "brands-id___ga",
    path: "/ga/brands/:id()",
    meta: indexWQo95DQTUEMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/brands/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "brands-id___it",
    path: "/it/brands/:id()",
    meta: indexWQo95DQTUEMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/brands/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "brands-id___lv",
    path: "/lv/brands/:id()",
    meta: indexWQo95DQTUEMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/brands/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "brands-id___lt",
    path: "/lt/brands/:id()",
    meta: indexWQo95DQTUEMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/brands/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "brands-id___mt",
    path: "/mt/brands/:id()",
    meta: indexWQo95DQTUEMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/brands/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "brands-id___pl",
    path: "/pl/brands/:id()",
    meta: indexWQo95DQTUEMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/brands/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "brands-id___pt",
    path: "/pt/brands/:id()",
    meta: indexWQo95DQTUEMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/brands/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "brands-id___ro",
    path: "/ro/brands/:id()",
    meta: indexWQo95DQTUEMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/brands/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "brands-id___sk",
    path: "/sk/brands/:id()",
    meta: indexWQo95DQTUEMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/brands/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "brands-id___sl",
    path: "/sl/brands/:id()",
    meta: indexWQo95DQTUEMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/brands/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "brands-id___es",
    path: "/es/brands/:id()",
    meta: indexWQo95DQTUEMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/brands/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "brands-id___sv",
    path: "/sv/brands/:id()",
    meta: indexWQo95DQTUEMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/brands/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "brands-id-wine-wineid___en",
    path: "/brands/:id()/wine/:wineid()",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/brands/[id]/wine/[wineid].vue").then(m => m.default || m)
  },
  {
    name: "brands-id-wine-wineid___fr",
    path: "/fr/brands/:id()/wine/:wineid()",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/brands/[id]/wine/[wineid].vue").then(m => m.default || m)
  },
  {
    name: "brands-id-wine-wineid___bg",
    path: "/bg/brands/:id()/wine/:wineid()",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/brands/[id]/wine/[wineid].vue").then(m => m.default || m)
  },
  {
    name: "brands-id-wine-wineid___hr",
    path: "/hr/brands/:id()/wine/:wineid()",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/brands/[id]/wine/[wineid].vue").then(m => m.default || m)
  },
  {
    name: "brands-id-wine-wineid___cs",
    path: "/cs/brands/:id()/wine/:wineid()",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/brands/[id]/wine/[wineid].vue").then(m => m.default || m)
  },
  {
    name: "brands-id-wine-wineid___da",
    path: "/da/brands/:id()/wine/:wineid()",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/brands/[id]/wine/[wineid].vue").then(m => m.default || m)
  },
  {
    name: "brands-id-wine-wineid___nl",
    path: "/nl/brands/:id()/wine/:wineid()",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/brands/[id]/wine/[wineid].vue").then(m => m.default || m)
  },
  {
    name: "brands-id-wine-wineid___et",
    path: "/et/brands/:id()/wine/:wineid()",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/brands/[id]/wine/[wineid].vue").then(m => m.default || m)
  },
  {
    name: "brands-id-wine-wineid___fi",
    path: "/fi/brands/:id()/wine/:wineid()",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/brands/[id]/wine/[wineid].vue").then(m => m.default || m)
  },
  {
    name: "brands-id-wine-wineid___de",
    path: "/de/brands/:id()/wine/:wineid()",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/brands/[id]/wine/[wineid].vue").then(m => m.default || m)
  },
  {
    name: "brands-id-wine-wineid___el",
    path: "/el/brands/:id()/wine/:wineid()",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/brands/[id]/wine/[wineid].vue").then(m => m.default || m)
  },
  {
    name: "brands-id-wine-wineid___hu",
    path: "/hu/brands/:id()/wine/:wineid()",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/brands/[id]/wine/[wineid].vue").then(m => m.default || m)
  },
  {
    name: "brands-id-wine-wineid___ga",
    path: "/ga/brands/:id()/wine/:wineid()",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/brands/[id]/wine/[wineid].vue").then(m => m.default || m)
  },
  {
    name: "brands-id-wine-wineid___it",
    path: "/it/brands/:id()/wine/:wineid()",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/brands/[id]/wine/[wineid].vue").then(m => m.default || m)
  },
  {
    name: "brands-id-wine-wineid___lv",
    path: "/lv/brands/:id()/wine/:wineid()",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/brands/[id]/wine/[wineid].vue").then(m => m.default || m)
  },
  {
    name: "brands-id-wine-wineid___lt",
    path: "/lt/brands/:id()/wine/:wineid()",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/brands/[id]/wine/[wineid].vue").then(m => m.default || m)
  },
  {
    name: "brands-id-wine-wineid___mt",
    path: "/mt/brands/:id()/wine/:wineid()",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/brands/[id]/wine/[wineid].vue").then(m => m.default || m)
  },
  {
    name: "brands-id-wine-wineid___pl",
    path: "/pl/brands/:id()/wine/:wineid()",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/brands/[id]/wine/[wineid].vue").then(m => m.default || m)
  },
  {
    name: "brands-id-wine-wineid___pt",
    path: "/pt/brands/:id()/wine/:wineid()",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/brands/[id]/wine/[wineid].vue").then(m => m.default || m)
  },
  {
    name: "brands-id-wine-wineid___ro",
    path: "/ro/brands/:id()/wine/:wineid()",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/brands/[id]/wine/[wineid].vue").then(m => m.default || m)
  },
  {
    name: "brands-id-wine-wineid___sk",
    path: "/sk/brands/:id()/wine/:wineid()",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/brands/[id]/wine/[wineid].vue").then(m => m.default || m)
  },
  {
    name: "brands-id-wine-wineid___sl",
    path: "/sl/brands/:id()/wine/:wineid()",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/brands/[id]/wine/[wineid].vue").then(m => m.default || m)
  },
  {
    name: "brands-id-wine-wineid___es",
    path: "/es/brands/:id()/wine/:wineid()",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/brands/[id]/wine/[wineid].vue").then(m => m.default || m)
  },
  {
    name: "brands-id-wine-wineid___sv",
    path: "/sv/brands/:id()/wine/:wineid()",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/brands/[id]/wine/[wineid].vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/",
    meta: index2hb1iHNl39Meta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___fr",
    path: "/fr",
    meta: index2hb1iHNl39Meta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___bg",
    path: "/bg",
    meta: index2hb1iHNl39Meta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___hr",
    path: "/hr",
    meta: index2hb1iHNl39Meta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___cs",
    path: "/cs",
    meta: index2hb1iHNl39Meta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___da",
    path: "/da",
    meta: index2hb1iHNl39Meta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___nl",
    path: "/nl",
    meta: index2hb1iHNl39Meta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___et",
    path: "/et",
    meta: index2hb1iHNl39Meta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___fi",
    path: "/fi",
    meta: index2hb1iHNl39Meta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/de",
    meta: index2hb1iHNl39Meta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___el",
    path: "/el",
    meta: index2hb1iHNl39Meta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___hu",
    path: "/hu",
    meta: index2hb1iHNl39Meta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___ga",
    path: "/ga",
    meta: index2hb1iHNl39Meta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___it",
    path: "/it",
    meta: index2hb1iHNl39Meta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___lv",
    path: "/lv",
    meta: index2hb1iHNl39Meta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___lt",
    path: "/lt",
    meta: index2hb1iHNl39Meta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___mt",
    path: "/mt",
    meta: index2hb1iHNl39Meta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___pl",
    path: "/pl",
    meta: index2hb1iHNl39Meta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___pt",
    path: "/pt",
    meta: index2hb1iHNl39Meta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___ro",
    path: "/ro",
    meta: index2hb1iHNl39Meta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___sk",
    path: "/sk",
    meta: index2hb1iHNl39Meta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___sl",
    path: "/sl",
    meta: index2hb1iHNl39Meta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/es",
    meta: index2hb1iHNl39Meta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___sv",
    path: "/sv",
    meta: index2hb1iHNl39Meta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "invoices___en",
    path: "/invoices",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/invoices.vue").then(m => m.default || m)
  },
  {
    name: "invoices___fr",
    path: "/fr/invoices",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/invoices.vue").then(m => m.default || m)
  },
  {
    name: "invoices___bg",
    path: "/bg/invoices",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/invoices.vue").then(m => m.default || m)
  },
  {
    name: "invoices___hr",
    path: "/hr/invoices",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/invoices.vue").then(m => m.default || m)
  },
  {
    name: "invoices___cs",
    path: "/cs/invoices",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/invoices.vue").then(m => m.default || m)
  },
  {
    name: "invoices___da",
    path: "/da/invoices",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/invoices.vue").then(m => m.default || m)
  },
  {
    name: "invoices___nl",
    path: "/nl/invoices",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/invoices.vue").then(m => m.default || m)
  },
  {
    name: "invoices___et",
    path: "/et/invoices",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/invoices.vue").then(m => m.default || m)
  },
  {
    name: "invoices___fi",
    path: "/fi/invoices",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/invoices.vue").then(m => m.default || m)
  },
  {
    name: "invoices___de",
    path: "/de/invoices",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/invoices.vue").then(m => m.default || m)
  },
  {
    name: "invoices___el",
    path: "/el/invoices",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/invoices.vue").then(m => m.default || m)
  },
  {
    name: "invoices___hu",
    path: "/hu/invoices",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/invoices.vue").then(m => m.default || m)
  },
  {
    name: "invoices___ga",
    path: "/ga/invoices",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/invoices.vue").then(m => m.default || m)
  },
  {
    name: "invoices___it",
    path: "/it/invoices",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/invoices.vue").then(m => m.default || m)
  },
  {
    name: "invoices___lv",
    path: "/lv/invoices",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/invoices.vue").then(m => m.default || m)
  },
  {
    name: "invoices___lt",
    path: "/lt/invoices",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/invoices.vue").then(m => m.default || m)
  },
  {
    name: "invoices___mt",
    path: "/mt/invoices",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/invoices.vue").then(m => m.default || m)
  },
  {
    name: "invoices___pl",
    path: "/pl/invoices",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/invoices.vue").then(m => m.default || m)
  },
  {
    name: "invoices___pt",
    path: "/pt/invoices",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/invoices.vue").then(m => m.default || m)
  },
  {
    name: "invoices___ro",
    path: "/ro/invoices",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/invoices.vue").then(m => m.default || m)
  },
  {
    name: "invoices___sk",
    path: "/sk/invoices",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/invoices.vue").then(m => m.default || m)
  },
  {
    name: "invoices___sl",
    path: "/sl/invoices",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/invoices.vue").then(m => m.default || m)
  },
  {
    name: "invoices___es",
    path: "/es/invoices",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/invoices.vue").then(m => m.default || m)
  },
  {
    name: "invoices___sv",
    path: "/sv/invoices",
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/invoices.vue").then(m => m.default || m)
  },
  {
    name: "login___en",
    path: "/login",
    meta: loginiAIJE5ybquMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___fr",
    path: "/fr/login",
    meta: loginiAIJE5ybquMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___bg",
    path: "/bg/login",
    meta: loginiAIJE5ybquMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___hr",
    path: "/hr/login",
    meta: loginiAIJE5ybquMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___cs",
    path: "/cs/login",
    meta: loginiAIJE5ybquMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___da",
    path: "/da/login",
    meta: loginiAIJE5ybquMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___nl",
    path: "/nl/login",
    meta: loginiAIJE5ybquMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___et",
    path: "/et/login",
    meta: loginiAIJE5ybquMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___fi",
    path: "/fi/login",
    meta: loginiAIJE5ybquMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___de",
    path: "/de/login",
    meta: loginiAIJE5ybquMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___el",
    path: "/el/login",
    meta: loginiAIJE5ybquMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___hu",
    path: "/hu/login",
    meta: loginiAIJE5ybquMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___ga",
    path: "/ga/login",
    meta: loginiAIJE5ybquMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___it",
    path: "/it/login",
    meta: loginiAIJE5ybquMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___lv",
    path: "/lv/login",
    meta: loginiAIJE5ybquMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___lt",
    path: "/lt/login",
    meta: loginiAIJE5ybquMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___mt",
    path: "/mt/login",
    meta: loginiAIJE5ybquMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___pl",
    path: "/pl/login",
    meta: loginiAIJE5ybquMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___pt",
    path: "/pt/login",
    meta: loginiAIJE5ybquMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___ro",
    path: "/ro/login",
    meta: loginiAIJE5ybquMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___sk",
    path: "/sk/login",
    meta: loginiAIJE5ybquMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___sl",
    path: "/sl/login",
    meta: loginiAIJE5ybquMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___es",
    path: "/es/login",
    meta: loginiAIJE5ybquMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___sv",
    path: "/sv/login",
    meta: loginiAIJE5ybquMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "register___en",
    path: "/register",
    meta: register6vArz3OYmtMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "register___fr",
    path: "/fr/register",
    meta: register6vArz3OYmtMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "register___bg",
    path: "/bg/register",
    meta: register6vArz3OYmtMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "register___hr",
    path: "/hr/register",
    meta: register6vArz3OYmtMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "register___cs",
    path: "/cs/register",
    meta: register6vArz3OYmtMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "register___da",
    path: "/da/register",
    meta: register6vArz3OYmtMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "register___nl",
    path: "/nl/register",
    meta: register6vArz3OYmtMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "register___et",
    path: "/et/register",
    meta: register6vArz3OYmtMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "register___fi",
    path: "/fi/register",
    meta: register6vArz3OYmtMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "register___de",
    path: "/de/register",
    meta: register6vArz3OYmtMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "register___el",
    path: "/el/register",
    meta: register6vArz3OYmtMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "register___hu",
    path: "/hu/register",
    meta: register6vArz3OYmtMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "register___ga",
    path: "/ga/register",
    meta: register6vArz3OYmtMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "register___it",
    path: "/it/register",
    meta: register6vArz3OYmtMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "register___lv",
    path: "/lv/register",
    meta: register6vArz3OYmtMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "register___lt",
    path: "/lt/register",
    meta: register6vArz3OYmtMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "register___mt",
    path: "/mt/register",
    meta: register6vArz3OYmtMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "register___pl",
    path: "/pl/register",
    meta: register6vArz3OYmtMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "register___pt",
    path: "/pt/register",
    meta: register6vArz3OYmtMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "register___ro",
    path: "/ro/register",
    meta: register6vArz3OYmtMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "register___sk",
    path: "/sk/register",
    meta: register6vArz3OYmtMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "register___sl",
    path: "/sl/register",
    meta: register6vArz3OYmtMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "register___es",
    path: "/es/register",
    meta: register6vArz3OYmtMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "register___sv",
    path: "/sv/register",
    meta: register6vArz3OYmtMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "resetpassword___en",
    path: "/resetpassword",
    meta: resetpasswordWJzeGeRBaCMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/resetpassword.vue").then(m => m.default || m)
  },
  {
    name: "resetpassword___fr",
    path: "/fr/resetpassword",
    meta: resetpasswordWJzeGeRBaCMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/resetpassword.vue").then(m => m.default || m)
  },
  {
    name: "resetpassword___bg",
    path: "/bg/resetpassword",
    meta: resetpasswordWJzeGeRBaCMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/resetpassword.vue").then(m => m.default || m)
  },
  {
    name: "resetpassword___hr",
    path: "/hr/resetpassword",
    meta: resetpasswordWJzeGeRBaCMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/resetpassword.vue").then(m => m.default || m)
  },
  {
    name: "resetpassword___cs",
    path: "/cs/resetpassword",
    meta: resetpasswordWJzeGeRBaCMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/resetpassword.vue").then(m => m.default || m)
  },
  {
    name: "resetpassword___da",
    path: "/da/resetpassword",
    meta: resetpasswordWJzeGeRBaCMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/resetpassword.vue").then(m => m.default || m)
  },
  {
    name: "resetpassword___nl",
    path: "/nl/resetpassword",
    meta: resetpasswordWJzeGeRBaCMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/resetpassword.vue").then(m => m.default || m)
  },
  {
    name: "resetpassword___et",
    path: "/et/resetpassword",
    meta: resetpasswordWJzeGeRBaCMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/resetpassword.vue").then(m => m.default || m)
  },
  {
    name: "resetpassword___fi",
    path: "/fi/resetpassword",
    meta: resetpasswordWJzeGeRBaCMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/resetpassword.vue").then(m => m.default || m)
  },
  {
    name: "resetpassword___de",
    path: "/de/resetpassword",
    meta: resetpasswordWJzeGeRBaCMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/resetpassword.vue").then(m => m.default || m)
  },
  {
    name: "resetpassword___el",
    path: "/el/resetpassword",
    meta: resetpasswordWJzeGeRBaCMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/resetpassword.vue").then(m => m.default || m)
  },
  {
    name: "resetpassword___hu",
    path: "/hu/resetpassword",
    meta: resetpasswordWJzeGeRBaCMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/resetpassword.vue").then(m => m.default || m)
  },
  {
    name: "resetpassword___ga",
    path: "/ga/resetpassword",
    meta: resetpasswordWJzeGeRBaCMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/resetpassword.vue").then(m => m.default || m)
  },
  {
    name: "resetpassword___it",
    path: "/it/resetpassword",
    meta: resetpasswordWJzeGeRBaCMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/resetpassword.vue").then(m => m.default || m)
  },
  {
    name: "resetpassword___lv",
    path: "/lv/resetpassword",
    meta: resetpasswordWJzeGeRBaCMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/resetpassword.vue").then(m => m.default || m)
  },
  {
    name: "resetpassword___lt",
    path: "/lt/resetpassword",
    meta: resetpasswordWJzeGeRBaCMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/resetpassword.vue").then(m => m.default || m)
  },
  {
    name: "resetpassword___mt",
    path: "/mt/resetpassword",
    meta: resetpasswordWJzeGeRBaCMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/resetpassword.vue").then(m => m.default || m)
  },
  {
    name: "resetpassword___pl",
    path: "/pl/resetpassword",
    meta: resetpasswordWJzeGeRBaCMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/resetpassword.vue").then(m => m.default || m)
  },
  {
    name: "resetpassword___pt",
    path: "/pt/resetpassword",
    meta: resetpasswordWJzeGeRBaCMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/resetpassword.vue").then(m => m.default || m)
  },
  {
    name: "resetpassword___ro",
    path: "/ro/resetpassword",
    meta: resetpasswordWJzeGeRBaCMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/resetpassword.vue").then(m => m.default || m)
  },
  {
    name: "resetpassword___sk",
    path: "/sk/resetpassword",
    meta: resetpasswordWJzeGeRBaCMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/resetpassword.vue").then(m => m.default || m)
  },
  {
    name: "resetpassword___sl",
    path: "/sl/resetpassword",
    meta: resetpasswordWJzeGeRBaCMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/resetpassword.vue").then(m => m.default || m)
  },
  {
    name: "resetpassword___es",
    path: "/es/resetpassword",
    meta: resetpasswordWJzeGeRBaCMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/resetpassword.vue").then(m => m.default || m)
  },
  {
    name: "resetpassword___sv",
    path: "/sv/resetpassword",
    meta: resetpasswordWJzeGeRBaCMeta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/resetpassword.vue").then(m => m.default || m)
  },
  {
    name: "verify___en",
    path: "/verify",
    meta: verifyy3qT442Jn8Meta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/verify.vue").then(m => m.default || m)
  },
  {
    name: "verify___fr",
    path: "/fr/verify",
    meta: verifyy3qT442Jn8Meta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/verify.vue").then(m => m.default || m)
  },
  {
    name: "verify___bg",
    path: "/bg/verify",
    meta: verifyy3qT442Jn8Meta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/verify.vue").then(m => m.default || m)
  },
  {
    name: "verify___hr",
    path: "/hr/verify",
    meta: verifyy3qT442Jn8Meta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/verify.vue").then(m => m.default || m)
  },
  {
    name: "verify___cs",
    path: "/cs/verify",
    meta: verifyy3qT442Jn8Meta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/verify.vue").then(m => m.default || m)
  },
  {
    name: "verify___da",
    path: "/da/verify",
    meta: verifyy3qT442Jn8Meta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/verify.vue").then(m => m.default || m)
  },
  {
    name: "verify___nl",
    path: "/nl/verify",
    meta: verifyy3qT442Jn8Meta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/verify.vue").then(m => m.default || m)
  },
  {
    name: "verify___et",
    path: "/et/verify",
    meta: verifyy3qT442Jn8Meta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/verify.vue").then(m => m.default || m)
  },
  {
    name: "verify___fi",
    path: "/fi/verify",
    meta: verifyy3qT442Jn8Meta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/verify.vue").then(m => m.default || m)
  },
  {
    name: "verify___de",
    path: "/de/verify",
    meta: verifyy3qT442Jn8Meta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/verify.vue").then(m => m.default || m)
  },
  {
    name: "verify___el",
    path: "/el/verify",
    meta: verifyy3qT442Jn8Meta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/verify.vue").then(m => m.default || m)
  },
  {
    name: "verify___hu",
    path: "/hu/verify",
    meta: verifyy3qT442Jn8Meta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/verify.vue").then(m => m.default || m)
  },
  {
    name: "verify___ga",
    path: "/ga/verify",
    meta: verifyy3qT442Jn8Meta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/verify.vue").then(m => m.default || m)
  },
  {
    name: "verify___it",
    path: "/it/verify",
    meta: verifyy3qT442Jn8Meta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/verify.vue").then(m => m.default || m)
  },
  {
    name: "verify___lv",
    path: "/lv/verify",
    meta: verifyy3qT442Jn8Meta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/verify.vue").then(m => m.default || m)
  },
  {
    name: "verify___lt",
    path: "/lt/verify",
    meta: verifyy3qT442Jn8Meta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/verify.vue").then(m => m.default || m)
  },
  {
    name: "verify___mt",
    path: "/mt/verify",
    meta: verifyy3qT442Jn8Meta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/verify.vue").then(m => m.default || m)
  },
  {
    name: "verify___pl",
    path: "/pl/verify",
    meta: verifyy3qT442Jn8Meta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/verify.vue").then(m => m.default || m)
  },
  {
    name: "verify___pt",
    path: "/pt/verify",
    meta: verifyy3qT442Jn8Meta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/verify.vue").then(m => m.default || m)
  },
  {
    name: "verify___ro",
    path: "/ro/verify",
    meta: verifyy3qT442Jn8Meta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/verify.vue").then(m => m.default || m)
  },
  {
    name: "verify___sk",
    path: "/sk/verify",
    meta: verifyy3qT442Jn8Meta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/verify.vue").then(m => m.default || m)
  },
  {
    name: "verify___sl",
    path: "/sl/verify",
    meta: verifyy3qT442Jn8Meta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/verify.vue").then(m => m.default || m)
  },
  {
    name: "verify___es",
    path: "/es/verify",
    meta: verifyy3qT442Jn8Meta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/verify.vue").then(m => m.default || m)
  },
  {
    name: "verify___sv",
    path: "/sv/verify",
    meta: verifyy3qT442Jn8Meta || {},
    component: () => import("/Users/jonathancarr/pinotqr/app/pages/verify.vue").then(m => m.default || m)
  }
]