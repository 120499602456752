import { useMutation } from "@tanstack/vue-query";

const goToStripePortal = async (data) => {
  return await fetchWithAuth(`/api/currentUser/stripePortal`, {
    method: "POST",
    body: data,
  });
};

export const useStripePortal = () => {
  return useMutation({
    mutationFn: goToStripePortal,
  });
};
