<script setup>
import { useI18n } from "vue-i18n";
import { useNotification } from "@kyvg/vue3-notification";
import * as z from "zod";
import { toTypedSchema } from "@vee-validate/zod";
import { useForm } from "vee-validate";
import { useQueryClient } from "@tanstack/vue-query";

const nuxtApp = useNuxtApp();

const { getLabelTranslation } = useLabelTranslation();

const queryClient = useQueryClient();

const props = defineProps({
  brand: { type: Object, required: true },
});

const { mutate: updateBrand } = useUpdateBrandMutation();

const formSchema = toTypedSchema(
  z.object({
    name: z
      .string({ required_error: "dashboard.brand-name-error" })
      .min(1, "dashboard.brand-name-error")
      .max(100, "Your brand name must be 100 characters or less"), // error should never show due to input charlimit

    showCodes: z.boolean().default(false).optional(),

    displaySmallValues: z
      .string({
        required_error: "error",
      })
      .min(1, "error"),
  }),
);

const form = useForm({
  validationSchema: formSchema,
  initialValues: {
    name: props.brand?.name,
    displaySmallValues: props.brand?.displaySmallValues || "negligible",
    showCodes: props.brand?.showCodes,
  },
});

const showDeletionConfirmation = ref(false);

const i18n = useI18n();

const { notify } = useNotification();

const onSubmit = form.handleSubmit(async (values) => {
  isLoading.value = true;

  const brandData = {
    brandId: props.brand?.id,
    ...values,
  };

  updateBrand(brandData, {
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: ["brands"],
      });
      notify({ type: "success", text: i18n.t("edit-brand.changes-saved") });
      modalStore.closeModal();
      isLoading.value = false;
    },
    onError: (err) => {
      notify({ type: "error", text: err.toString() });
      console.error("Error updating brand!");
      isLoading.value = false;
    },
  });
});

const onBrandDelete = async () => {
  isLoadingDeletion.value = true;

  deleteBrand(
    { brandId: props.brand?.id },
    {
      onSuccess: async (data) => {
        await queryClient.refetchQueries({
          queryKey: ["brands"],
        });
        queryClient.refetchQueries({ queryKey: ["user"] });
        await navigateTo(nuxtApp.$localePath("/"));

        notify({
          type: "success",
          text: i18n.t("edit-brand.brand-deleted", [data.name]),
        });
        open.value = false;
        isLoadingDeletion.value = false;
      },
      onError: () => {
        notify({
          type: "error",
          text: i18n.t("edit-brand.something-went-wrong"),
        });
        console.error("Error updating brand!");
        isLoadingDeletion.value = false;
      },
    },
  );
};

const isLoading = ref(false);
const isLoadingDeletion = ref(false);

const open = ref(false);

const emit = defineEmits();

watch(
  () => open.value,
  (val) => {
    if (val) {
      showDeletionConfirmation.value = false;
      isLoading.value = false;
      isLoadingDeletion.value = false;
      form.setValues({
        name: props.brand?.name,
        displaySmallValues: props.brand?.displaySmallValues || "negligible",
        showCodes: props.brand?.showCodes,
      });
    }
  },
);
const modalStore = useModalStore();
</script>

<template>
  <div class="flex flex-col gap-4">
    <DialogHeader>
      <DialogTitle>{{ $t("edit-brand.title") }}</DialogTitle>
    </DialogHeader>
    <form @submit="onSubmit" novalidate class="flex flex-col gap-8">
      <FormField
        v-slot="{ componentField }"
        type="radio"
        name="displaySmallValues"
      >
        <FormItem class="space-y-3">
          <div class="space-y-2">
            <FormLabel class="pointer-events-none">
              {{ $t("edit-brand.low-values-label") }}
            </FormLabel>

            <p>
              {{ $t("edit-brand.low-values-sublabel") }} “0g”
              {{ $t("edit-brand.or") }} “{{
                getLabelTranslation("t_negligible")
              }}” –
              {{ $t("edit-brand.its-your-choice") }}
            </p>
          </div>

          <FormControl>
            <RadioGroup v-bind="componentField" default-value="zero-grams">
              <div class="flex items-center space-x-3">
                <RadioGroupItem id="zero-grams" value="zero-grams" />
                <Label for="zero-grams">0g</Label>
              </div>
              <div class="flex items-center space-x-3">
                <RadioGroupItem id="negligible" value="negligible" />
                <Label for="negligible">{{
                  getLabelTranslation("t_negligible")
                }}</Label>
              </div>
            </RadioGroup>
          </FormControl>
          <FormMessage />
        </FormItem>
      </FormField>

      <hr />

      <FormField v-slot="{ value, handleChange }" type="radio" name="showCodes">
        <FormItem class="space-y-3">
          <div class="space-y-2">
            <FormLabel class="pointer-events-none">{{
              $t("edit-brand.e-number-title")
            }}</FormLabel>

            <p v-html="$t('edit-brand.e-numbers-desc')" />
          </div>

          <FormControl>
            <div class="flex items-center flex-row gap-3">
              <Switch
                id="show-codes"
                :checked="value"
                @update:checked="handleChange"
              />
              <Label for="show-codes">{{
                $t("edit-brand.e-numbers-label")
              }}</Label>
            </div>
          </FormControl>

          <FormMessage />
        </FormItem>
      </FormField>

      <hr />

      <FormField v-slot="{ componentField }" name="name">
        <FormItem>
          <div class="space-y-2">
            <FormLabel class="pointer-events-none">
              {{ $t("edit-brand.rename-label") }}
            </FormLabel>
            <p>
              {{ $t("edit-brand.rename-sublabels") }}
            </p>
          </div>

          <FormControl>
            <Input
              type="text"
              :placeholder="$t('dashboard.enter-brand-name-placeholder')"
              v-bind="componentField"
              maxlength="100"
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      </FormField>

      <hr />

      <div>
        <Text variant="body_strong" class="mb-2">{{
          $t("edit-brand.delete-label")
        }}</Text>
        <Text variant="body" class="mb-5">{{
          $t("edit-brand.delete-warning")
        }}</Text>
        <Button
          type="button"
          @click.prevent="modalStore.openDeleteBrand({ brand })"
          variant="destructive-outlined"
          >{{ $t("edit-brand.delete-butto") }}</Button
        >
      </div>

      <div class="bg-mono-light-silver rounded-xl p-8" :withDivider="false">
        <Alert fullwidth class="mb-8">
          {{ $t("edit-brand.changes-warning") }}
        </Alert>

        <div class="flex justify-between flex-col sm:flex-row gap-6">
          <Button
            type="button"
            size="lg"
            variant="secondary-outlined"
            @click.prevent="modalStore.closeModal"
          >
            {{ $t("edit-brand.discard") }}
          </Button>
          <Button size="lg" type="submit" :loading="isLoading">
            {{ $t("edit-brand.save") }}
          </Button>
        </div>
      </div>
    </form>
  </div>
</template>
