import { useQuery } from "@tanstack/vue-query";

const fetchCurrentUserSubscription = async () => {
  const subscriptionData = await fetchWithAuth("/api/currentUser/subscription");
  const user = useCurrentUser();

  if (!subscriptionData.fullname && user.value?.displayName) {
    const firstName = user.value.displayName.split(" ")[0];
    const initial = firstName.charAt(0);
    return {
      ...subscriptionData,
      firstName,
      initial,
    };
  }

  return subscriptionData;
};

const useCurrentUserSubscriptionQuery = () => {
  // TODO: Future optimization
  // Decouple subscription fetching from user fetching, so we dont need to refetch subscription details so often

  return useQuery({
    queryKey: ["user", "currentSubscription"],
    queryFn: fetchCurrentUserSubscription,
    refetchOnMount: false,
  });
};

export default useCurrentUserSubscriptionQuery;
