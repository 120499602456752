<script setup>
import WineForm from "~/components/forms/WineForm.vue";
import { useQueryClient } from "@tanstack/vue-query";
import { useNotification } from "@kyvg/vue3-notification";

const { mutate: updateWine } = useUpdateWineMutation();
const queryClient = useQueryClient();

//set default value for props wine
const props = defineProps({
  wine: {
    type: Object,
    required: true,
  },
  brand: { required: true },
  source: { required: false, default: "unknown" },
});

const { $intercom } = useNuxtApp();

const loading = ref(false);
const emit = defineEmits();

const { notify } = useNotification();

onMounted(() => {
  console.log("Opened");
  $intercom.trackEvent("Label edit form opened", {
    name: props.wine.name,
    source: props.source,
  });
});

async function updateProduct(values) {
  if (loading.value) {
    return;
  }
  loading.value = true;

  const brandId = props.brand.id;
  const wineId = props.wine.id;

  console.log({ brandId, wineId });

  updateWine(
    {
      wineId,
      brandId,
      ...values,
    },
    {
      onSuccess: async () => {
        await queryClient.refetchQueries(["brands"]);

        modalStore.closeModal();
        loading.value = false;

        notify({
          type: "success",
          text: `${values.name} has been updated`,
        });
      },
      onError: (err) => {
        console.log(err);
        loading.value = false;
      },
    },
  );
}
const modalStore = useModalStore();
</script>

<template>
  <div class="flex flex-col gap-4">
    <DialogHeader>
      <DialogTitle>
        {{ $t("wine-modal.title") }}
      </DialogTitle>
    </DialogHeader>

    <p class="modal-intro">
      {{ $t("wine-modal.intro") }}
    </p>

    <WineForm
      :wine="wine"
      @submitForm="updateProduct"
      @cancel="modalStore.closeModal"
      :loading="loading"
      :brand="brand"
      source="update"
    />
  </div>
</template>
