import {
  faSpinner as freeFasFaSpinner,
  faCheck as freeFasFaCheck,
  faPlus as freeFasFaPlus,
  faPencil as freeFasFaPencil,
  faEye as freeFasFaEye,
  faEyeSlash as freeFasFaEyeSlash,
  faXmark as freeFasFaXmark,
  faCloudUpload as freeFasFaCloudUpload,
  faInfoCircle as freeFasFaInfoCircle,
  faExclamationCircle as freeFasFaExclamationCircle,
  faFilePdf as freeFasFaFilePdf,
  faTrash as freeFasFaTrash,
  faTrashCan as freeFasFaTrashCan,
  faEllipsis as freeFasFaEllipsis,
  faDiamondTurnRight as freeFasFaDiamondTurnRight,
  faCircleXmark as freeFasFaCircleXmark,
  faGlobe as freeFasFaGlobe,
  faArrowRightFromBracket as freeFasFaArrowRightFromBracket,
  faLifeRing as freeFasFaLifeRing,
  faGear as freeFasFaGear,
  faCreditCard as freeFasFaCreditCard,
  faLock as freeFasFaLock,
  faUnlock as freeFasFaUnlock
} from '@fortawesome/free-solid-svg-icons'

export default {freeFasFaSpinner, freeFasFaCheck, freeFasFaPlus, freeFasFaPencil, freeFasFaEye, freeFasFaEyeSlash, freeFasFaXmark, freeFasFaCloudUpload, freeFasFaInfoCircle, freeFasFaExclamationCircle, freeFasFaFilePdf, freeFasFaTrash, freeFasFaTrashCan, freeFasFaEllipsis, freeFasFaDiamondTurnRight, freeFasFaCircleXmark, freeFasFaGlobe, freeFasFaArrowRightFromBracket, freeFasFaLifeRing, freeFasFaGear, freeFasFaCreditCard, freeFasFaLock, freeFasFaUnlock}