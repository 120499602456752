<script lang="ts">
import type { HTMLAttributes, InjectionKey } from "vue";

export const FORM_ITEM_INJECTION_KEY = Symbol() as InjectionKey<string>;
</script>

<script lang="ts" setup>
import { provide } from "vue";
import { useId } from "radix-vue";
import { cn } from "@/lib/utils";

const isBlurred = ref(false);

const props = defineProps<{
  class?: HTMLAttributes["class"];
}>();

const id = useId();
provide(FORM_ITEM_INJECTION_KEY, id);
provide("isBlurred", isBlurred);
</script>

<template>
  <div :class="cn('flex flex-col gap-3', props.class)">
    <slot />
  </div>
</template>
