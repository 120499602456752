<script setup>
import { useI18n } from "vue-i18n";

import RecyclingInformationItem from "./RecyclingInformationItem.vue";

const i18n = useI18n();

const { isLabelTranslationsFetched, getLabelTranslation } =
  useLabelTranslation();

const props = defineProps({
  recyclingInformation: {
    type: Array,
    required: true,
  },
  title: {
    type: String,
    required: false,
  },
  greyBackground: {
    type: Boolean,
    required: false,
    default: false,
  },
});
</script>

<template>
  <div>
    <p v-if="title" class="label">{{ title }}</p>
    <div class="flex flex-col gap-3 items-center">
      <RecyclingInformationItem
        v-for="item in recyclingInformation"
        :type="item.type"
        :material="item.material"
        :recyclingMethod="item.recyclingMethod"
        :greyBackground="greyBackground"
      />
      <p class="text-sm mt-3">
        {{ getLabelTranslation("check-recycling-guideliens") }}
      </p>
    </div>
  </div>
</template>

<style scoped>
.label {
  margin: 60px 0 20px;
  font-size: 1.4rem;
  font-weight: 900;
  color: #121212;
}
</style>
