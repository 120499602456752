<script setup>
const props = defineProps({
  title: { type: String },
  loading: { type: Boolean },
});

const { isLoading, data } = useCurrentUserSubscriptionQuery();

const { locale } = useI18n();

function formatDateString(timestamp) {
  console.log(timestamp._seconds);
  const date = new Date(timestamp._seconds * 1000);
  const options = { year: "numeric", month: "long", day: "numeric" };
  return date.toLocaleDateString(locale.value || "en", options);
}
</script>

<template>
  <Card :title="title" class="card" with-divider="false">
    <div v-if="isLoading">
      <font-awesome icon="spinner" spin />
    </div>

    <div class="subscription" v-else>
      <div>
        <Text>{{ $t("subscription.current-plan") }}</Text>
        <div class="description">
          {{ $t(data?.planName) }}
        </div>
      </div>

      <div>
        <Text>{{ $t("subscription.usage") }}</Text>
        <div class="description">
          <p>
            {{
              data.allowUnlimitedDrafts
                ? data?.numberPublishedLabels || 0
                : data?.numberActiveLabels || 0
            }}
            / {{ data?.planLimit }}
          </p>
        </div>
      </div>
    </div>

    <Notice variant="primary" class="w-full mt-6" v-if="data?.cancel_at"
      >Your subscription has been cancelled but will remain active until the
      {{ formatDateString(data?.cancel_at) }}.</Notice
    >

    <template #footer>
      <slot name="actions" />
    </template>
  </Card>
</template>

<style scoped>
.subscription {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  gap: 16px;

  > div {
    width: calc(50% - 8px);
  }

  .description {
    font-size: 22px;
    font-weight: 400;
  }

  h2 {
    color: #6f6f8d;
    margin-bottom: 10px;
  }
}
</style>
