<script setup lang="ts">
import { cn } from "@/lib/utils";
import {
  NavigationMenuViewport,
  type NavigationMenuViewportProps,
  useForwardProps,
} from "radix-vue";
import { computed, type HTMLAttributes } from "vue";

const props = defineProps<
  NavigationMenuViewportProps & { class?: HTMLAttributes["class"] }
>();

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props;

  return delegated;
});

const forwardedProps = useForwardProps(delegatedProps);
</script>

<template>
  <div class="absolute right-0 top-full flex justify-center">
    <NavigationMenuViewport
      v-bind="forwardedProps"
      :class="
        cn(
          'origin-top-center relative mt-1.5 h-[--radix-navigation-menu-viewport-height] w-full overflow-hidden rounded-lg bg-mono-white text-mono-dark-grey shadow-[0_3px_25px_-5px_rgba(0,0,0,0.1)] data-[state=open]:animate-in data-[state=open]:slide-in-from-top-6  data-[state=closed]:slide-out-to-top-6 data-[state=closed]:animate-out data-[state=open]:fade-in data-[state=closed]:fade-out  data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-90 md:w-[--radix-navigation-menu-viewport-width]',
          props.class,
        )
      "
    />
  </div>
</template>
