<script setup lang="ts">
import type { HTMLAttributes } from "vue";
import { SelectLabel, type SelectLabelProps } from "radix-vue";
import { cn } from "@/lib/utils";

const props = defineProps<
  SelectLabelProps & { class?: HTMLAttributes["class"] }
>();
</script>

<template>
  <SelectLabel
    :class="
      cn(
        'mx-1 py-1.5 pl-5 pr-2 text-[10px] leading-[14px] rounded-lg text-mono-black font-bold uppercase bg-mono-light-silver',
        props.class,
      )
    "
  >
    <slot />
  </SelectLabel>
</template>
