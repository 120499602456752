import { defineStore } from "pinia";

// Must match file name in components/modalContent
export const CHANGE_LANGUAGE = "ChangeLanguage";

export const ADD_BRAND = "AddBrand";
export const EDIT_BRAND = "EditBrand";
export const DELETE_BRAND = "DeleteBrand";

export const EDIT_WINE = "EditWine";
export const UNPUBLISH_WINE = "UnpublishWine";
export const REDIRECT_WINE = "RedirectWine";
export const REMOVE_WINE_REDIRECT = "RemoveWineRedirect";
export const UPDATE_WINE_REDIRECT = "UpdateWineRedirect";
export const DELETE_WINE = "DeleteWine";

export const SUBSCRIBE_OR_UPGRADE = "SubscribeOrUpgrade";
export const UNSUBSCRIBE = "Unsubscribe";

const MODAL_SIZES = {
  [CHANGE_LANGUAGE]: "lg",

  [ADD_BRAND]: "sm",
  [EDIT_BRAND]: "lg",
  [DELETE_BRAND]: "sm",

  [EDIT_WINE]: "lg",
  [REDIRECT_WINE]: "md",
  [DELETE_WINE]: "sm",
  [UNPUBLISH_WINE]: "sm",
  [REMOVE_WINE_REDIRECT]: "md",
  [UPDATE_WINE_REDIRECT]: "md",

  [SUBSCRIBE_OR_UPGRADE]: "sm",
  [UNSUBSCRIBE]: "md",
};

export const useModalStore = defineStore("modal", () => {
  const isOpen = ref(false);
  const modalKey = ref();
  const modalProps = ref({});

  const {
    isLoading,
    data: userData,
    suspense,
    isFetched,
  } = useCurrentUserSubscriptionQuery();

  const size = computed(() => MODAL_SIZES[modalKey.value]);

  const openEditBrand = (props) => {
    isOpen.value = true;
    modalKey.value = EDIT_BRAND;
    modalProps.value = props;
  };

  const openDeleteBrand = (props) => {
    isOpen.value = true;
    modalKey.value = DELETE_BRAND;
    modalProps.value = props;
  };

  const openAddBrand = (props) => {
    isOpen.value = true;
    modalKey.value = ADD_BRAND;
    modalProps.value = props;
  };

  const openDeleteWine = (props) => {
    isOpen.value = true;
    modalKey.value = DELETE_WINE;
    modalProps.value = props;
  };

  const openEditWine = (props) => {
    isOpen.value = true;
    modalKey.value = EDIT_WINE;
    modalProps.value = props;
  };

  const openUnpublishWine = (props) => {
    isOpen.value = true;
    modalKey.value = UNPUBLISH_WINE;
    modalProps.value = props;
  };

  const openSubscribeOrUpgrade = (props) => {
    isOpen.value = true;
    modalKey.value = SUBSCRIBE_OR_UPGRADE;
    modalProps.value = props;
  };

  const openUnsubscribe = (props) => {
    isOpen.value = true;
    modalKey.value = UNSUBSCRIBE;
    modalProps.value = props;
  };

  const openRedirectWine = (props) => {
    isOpen.value = true;
    modalKey.value = REDIRECT_WINE;
    modalProps.value = props;
  };

  const openRemoveWineRedirect = (props) => {
    isOpen.value = true;
    console.log(userData);
    if (userData.value.canCreateLabel) {
      modalKey.value = REMOVE_WINE_REDIRECT;
    } else {
      modalKey.value = SUBSCRIBE_OR_UPGRADE;
    }
    modalProps.value = props;
  };

  const openUpdateWineRedirect = (props) => {
    isOpen.value = true;
    modalKey.value = UPDATE_WINE_REDIRECT;
    modalProps.value = props;
  };

  const openChangeLanguage = (props) => {
    isOpen.value = true;
    modalKey.value = CHANGE_LANGUAGE;
    modalProps.value = props;
  };

  const closeModal = () => {
    isOpen.value = false;
  };

  return {
    isOpen,
    size,
    modalKey: readonly(modalKey),
    modalProps: readonly(modalProps),

    openChangeLanguage,

    openEditBrand,
    openDeleteBrand,
    openDeleteWine,
    openEditWine,
    openUnpublishWine,
    openSubscribeOrUpgrade,
    openUnsubscribe,
    openRedirectWine,
    openAddBrand,
    openRemoveWineRedirect,
    openUpdateWineRedirect,

    closeModal,
  };
});
