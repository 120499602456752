<script setup>
const props = defineProps({
  source: { type: String, default: "unknown" },
});
const emit = defineEmits();

const { currencies, currentCurrency, currencyLocked } = useCurrencies();
const { data, isLoading: isLoadingProducts } = useProductsQuery();
const {
  isLoading,
  data: { allowUnlimitedDrafts, currency: userSetCurrency },
} = useCurrentUserSubscriptionQuery();

const { $intercom } = useNuxtApp();

const currencyOptions = computed(() => {
  return currencies.map((currency) => {
    return {
      label: currency,
      value: currency,
    };
  });
});

onMounted(() => {
  $intercom.trackEvent("Subscribe modal viewed", { trigger: props.source });
});

const freeProduct = {
  planLimitDescription: "plans.free-limit-description",
};
</script>

<template>
  <div v-if="!isLoading">
    <div class="modal-title">
      {{ $t("subscription-modal.title") }}
    </div>
    <p class="modal-intro" v-if="allowUnlimitedDrafts">
      {{ $t("subscription-modal.intro-grandfathered") }}
    </p>

    <p class="modal-intro" v-else>
      {{ $t("subscription-modal.intro") }}
    </p>

    <SubscriptionCard class="subscription-card" />

    <template v-if="!currencyLocked">
      <h2 class="modal-section-title">
        {{ $t("subscription-modal.billing-currency") }}
      </h2>
      <div class="description">
        {{ $t("subscription-modal.billing-currency-description") }}
      </div>
      <div class="w-52 mt-3">
        <Select v-model="currentCurrency">
          <FormControl>
            <SelectTrigger>
              <SelectValue />
            </SelectTrigger>
          </FormControl>
          <SelectContent>
            <SelectGroup>
              <SelectItem
                v-for="currencyOption in currencyOptions"
                :value="currencyOption.value"
              >
                <span class="flex flex-row items-center content-center gap-4">
                  <Flag :country="currencyOption.value" />
                  {{ currencyOption.label.toUpperCase() }}
                </span>
              </SelectItem>
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>
    </template>

    <div>
      <h2 class="modal-section-title">
        {{ $t("subscription-modal.plan-title") }}
      </h2>
      <div class="description mt-2 mb-2">
        {{ $t("subscription-modal.plan-description") }}
      </div>
      <div class="subscription-plans">
        <table v-if="!isLoadingProducts">
          <price-line :product="freeProduct" current-plan />
          <template
            v-for="product in data.products"
            :key="product.id"
            class="subscription-plan"
          >
            <price-line :product="product"></price-line>
          </template>
        </table>
      </div>
      <p class="exclude-tax">
        {{ $t("subscription-modal.prices-exclude-tax") }}
      </p>
    </div>
  </div>
</template>

<style scoped>
.new-subscription-modal-content {
  max-width: 540px;
}
.modal-title {
  margin-bottom: 10px;
}

.subscription-card {
  margin: 5px 0 10px;
}

.subscription-plans {
  margin: 20px 0;
}

table {
  width: 100%;
  border-spacing: 0 10px;
  border-collapse: separate;
}

.exclude-tax {
  font-size: 12px;
  color: #6f6f8d;
}

.multiselect-pinot {
  width: 180px;
  background-color: #f7f7f7;
  border: none;
  padding: 20px 10px;
  margin: 20px 0;
}
.multiselect-pinotqr-option {
  display: flex;
  align-items: center;
  padding: 0px 10px;
}
</style>

<style>
.multiselect-dropdown {
  max-height: 50vh !important;
}
</style>
