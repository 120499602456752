<script setup>
const props = defineProps({
  country: String,
});

const imgSrc = computed(() => `/flags/${props.country}.svg`);
</script>

<template>
  <span>
    <img :src="imgSrc" />
  </span>
</template>
