import { cva } from "class-variance-authority";

export { default as NavigationMenu } from "./NavigationMenu.vue";
export { default as NavigationMenuContent } from "./NavigationMenuContent.vue";
export { default as NavigationMenuItem } from "./NavigationMenuItem.vue";
export { default as NavigationMenuLink } from "./NavigationMenuLink.vue";
export { default as NavigationMenuList } from "./NavigationMenuList.vue";
export { default as NavigationMenuTrigger } from "./NavigationMenuTrigger.vue";
export { default as NavigationMenuViewport } from "./NavigationMenuViewport.vue";

export const navigationMenuTriggerStyle = cva(
  "group inline-flex  w-max items-center justify-center rounded-full bg-mono-off-white pl-5 pr-[5px] py-[5px] text-sm text-mono-dark-grey font-medium transition-colors hover:bg-mono-white hover:ring-2 hover:ring-mono-silver hover:text-mono-black focus:outline-none  focus:ring-2 focus:ring-mono-silver  focus:text-mono-black disabled:pointer-events-none disabled:opacity-50 data-[active]:bg-mono-white data-[state=open]:bg-mono-white data-[state=open]:ring-2 data-[state=open]:ring-mono-black transition-all",
);
