<script setup>
import { useNotification } from "@kyvg/vue3-notification";

const props = defineProps({
  source: { type: String, default: "unknown" },
});

const { isLoading, data } = useCurrentUserSubscriptionQuery();

const { mutate } = useStripePortal();

const isRedirecting = ref(false);

const notify = useNotification();

const { locale } = useI18n();

function formatDateString(timestamp) {
  const date = new Date(timestamp._seconds * 1000);
  const options = { year: "numeric", month: "long", day: "numeric" };
  return date.toLocaleDateString(locale.value || "en", options);
}

const goToStripeCancellation = () => {
  if (isRedirecting.value) {
    return;
  }

  isRedirecting.value = true;

  const type = "subscription_cancel";

  mutate(
    { type, return_url: window.location.href },
    {
      onSuccess: (url) => {
        window.location = url;
      },
      onError: () => {
        isRedirecting.value = false;
        notify({ type: "error", text: "Something went wrong." });
      },
    },
  );
};
</script>

<template>
  <div class="flex flex-col gap-4">
    <h1 v-if="isLoading"><font-awesome icon="spinner" spin></font-awesome></h1>

    <Text variant="widget_title">{{ $t("unsubscribe.title") }}</Text>

    <Text> {{ $t("unsubscribe.are-you-sure") }} </Text>

    <Text
      ><strong>{{
        $t("unsubscribe.remain-active-until", [
          formatDateString(data.current_period_end),
        ])
      }}</strong>
      {{ $t("unsubscribe.on-this-date") }}</Text
    >

    <Button
      variant="destructive"
      size="lg"
      class="mt-6"
      :loading="isRedirecting"
      @click="goToStripeCancellation"
      >{{ $t("unsubscribe.confirm") }}</Button
    >
    <Button variant="secondary-outlined" size="lg" class="mt-2">{{
      $t("unsubscribe.cancel")
    }}</Button>

    <Text variant="body_light_sm" class="text-center">{{
      $t("unsubscribe.your-plan-is-paid-until", [
        formatDateString(data.current_period_end),
      ])
    }}</Text>
  </div>
</template>
