<script setup lang="ts">
import type { HTMLAttributes } from "vue";
import { type AlertVariants, alertVariants, alertIconVariants } from ".";
import { cn } from "@/lib/utils";

const props = defineProps<{
  class?: HTMLAttributes["class"];
  variant?: AlertVariants["variant"];
  fullwidth?: boolean;
  actionText?: string;
}>();

const icon = computed(() => {
  if (props.variant === "destructive") return "exclamation-circle";

  return "info-circle";
});
</script>

<template>
  <div
    :class="cn(alertVariants({ variant, fullwidth }), props.class)"
    role="alert"
  >
    <div :class="cn(alertIconVariants({ variant }))">
      <font-awesome :icon="icon" size="1x" />
    </div>
    <div
      class="flex flex-grow gap-3 items-baseline justify-between p-5 flex-row flex-wrap"
    >
      <slot />
      <Button
        variant="link"
        size="sm"
        v-if="actionText"
        @click="$emit('action')"
        >{{ actionText }}</Button
      >
    </div>
  </div>
</template>
