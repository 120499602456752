<script setup>
import { useRouter } from "vue-router";
const router = useRouter();

const { mutate } = useStripePortal();

const isRedirecting = ref(false);

const goToStripePortal = () => {
  if (isRedirecting.value) {
    return;
  }

  isRedirecting.value = true;

  mutate(
    { return_url: window.location.href },
    {
      onSuccess: (url) => {
        window.location = url;
      },
      onError: () => {
        isRedirecting.value = false;
        notify({ type: "error", text: "Something went wrong." });
      },
    },
  );
};

const { isFetched, data } = useCurrentUserSubscriptionQuery();
</script>

<template>
  <div>
    <div class="p-3" v-if="data?.isPastDue">
      <Notice variant="destructive" :fullwidth="true">
        <div
          class="flex w-full text-center flex-col md:justify-center md:items-center md:flex-row gap-2 text-wrap py-[14px] px-[20px] md:p-0 md:py-0"
        >
          <font-awesome
            class="h-[20px] md:h-[18px]"
            icon="exclamation-circle"
          />
          {{ $t("renewal.payment-failed") }}

          <Button
            @click="goToStripePortal"
            variant="destructive"
            size="sm"
            :loading="isRedirecting"
            >{{ $t("payment-method.update") }}</Button
          >
        </div>
      </Notice>
    </div>

    <div class="topbar">
      <div
        class="topbar-container flex flex-row items-center justify-between gap-4"
      >
        <svg
          class="logo flex-shrink-0"
          viewBox="0 0 30 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          @click="() => router.push(localeLocation('/'))"
        >
          <g class="group-1">
            <rect y="10" width="3.75" height="30" fill="" />
            <rect y="36.25" width="30" height="3.75" fill="" />
          </g>

          <g class="group-2">
            <rect x="7.5" y="10" width="3.75" height="22.5" fill="blue" />
            <rect x="7.5" y="28.75" width="22.5" height="3.75" fill="blue" />
          </g>

          <g class="group-3">
            <rect x="15" y="10" width="3.75" height="15" fill="pink" />
            <rect x="15" y="21.25" width="15" height="3.75" fill="pink" />
          </g>

          <g class="group-4">
            <rect x="22.5" y="10" width="3.75" height="7.5" fill="black" />
            <rect x="22.5" y="13.75" width="7.5" height="3.75" fill="black" />
          </g>
        </svg>

        <div class="top-actions">
          <LanguageModalTrigger variant="icon" v-if="isFetched" />
          <AccountNavDropdown />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.topbar {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  background: white;
  box-shadow: 0px 1px 0px 0px rgba(230, 230, 230, 1);
}
.topbar-container {
  width: 960px;
  max-width: 100%;
  margin: 0 auto;

  @media screen and (max-width: 1000px) {
    padding: 0 20px;
  }
}

.logo {
  cursor: pointer;
  width: 30px;
  height: 50px;
  transition: 160ms ease-in-out all;

  rect {
    fill: hsl(var(--mono-black));
  }

  &:hover {
    transform: scale(0.8);
  }
}
.topbar-account {
  cursor: pointer;
}
.top-actions {
  display: flex;
  justify-content: end;
  gap: 16px;
  align-items: center;
  flex-shrink: 1;
}
</style>
