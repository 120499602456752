import { useMutation } from "@tanstack/vue-query";

const updateWine = async ({ wineId, brandId, ...wineData }) => {
  return await fetchWithAuth(
    `/api/currentUser/brands/${brandId}/wines/${wineId}`,
    {
      method: "PATCH",
      body: { wineData },
    }
  );
};

export const useUpdateWineMutation = () => {
  return useMutation({
    mutationFn: updateWine,
  });
};
