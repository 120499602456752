<script setup>
const { locale } = useI18n();
const modalStore = useModalStore();

const props = defineProps({
  variant: { type: String, default: "default" },
});
</script>

<template>
  <link
    v-for="l in getLocales()"
    rel="preload"
    as="image"
    :href="`/flags/langs/${l.value}.svg`"
  />

  <Button
    v-if="variant == 'icon'"
    @click="modalStore.openChangeLanguage"
    size="lg"
    variant="tertiary"
    class="h-[50px] w-[50px] rounded-full bg-mono-off-white"
    ><LanguageFlag :locale="locale"
  /></Button>

  <Button
    v-else
    @click="modalStore.openChangeLanguage"
    variant="tertiary"
    class="uppercase gap-3"
    >{{ locale }} <LanguageFlag :locale="locale"
  /></Button>
</template>
