export const useLabelTranslation = () => {
  const { data, isFetched } = useLabelTranslationsQuery();

  const { locale } = useI18n();

  const getLabelTranslation = (key) => {
    if (!isFetched.value) {
      return "";
    }

    return data.value[key][locale.value];
  };

  return {
    isLabelTranslationsFetched: isFetched,
    getLabelTranslation,
  };
};
