<script setup>
import { cn } from "@/lib/utils";

const i18n = useI18n();

const nuxtApp = useNuxtApp();

const modalStore = useModalStore();

const user = useCurrentUser();

const { mutate: updateUserDetails } = useUpdateUserDetailsMutation();

const setLanguage = async (l) => {
  const baseRoute = nuxtApp.$getRouteBaseName();
  await navigateTo(nuxtApp.$localePath(baseRoute, l));

  console.log(user);

  if (user.value) {
    updateUserDetails({ locale: l });
  }

  modalStore.closeModal();
};

const isSelected = (l) => l == i18n.locale.value;
</script>

<template>
  <div class="flex flex-col gap-4">
    <DialogHeader>
      <DialogTitle>{{ $t("language-modal.title") }}</DialogTitle>
    </DialogHeader>

    <div class="grid grid-cols-2 md:grid-cols-3 gap-1">
      <Button
        v-for="locale in getLocales()"
        @click="() => setLanguage(locale.value)"
        :squared="true"
        :variant="isSelected(locale.value) ? 'tertiary' : 'ghost'"
        :class="cn('justify-between')"
        ><div class="flex flex-row items-center gap-3">
          <LanguageFlag :locale="locale.value" />
          {{ locale.name }}
        </div>
        <font-awesome icon="check" v-if="isSelected(locale.value)" />
      </Button>
    </div>
  </div>
</template>
