<script setup lang="ts">
import type { HTMLAttributes } from "vue";
import { useVModel } from "@vueuse/core";
import { cn } from "@/lib/utils";
import { useFormField } from "../form/useFormField";

const props = defineProps<{
  defaultValue?: string | number;
  modelValue?: string | number;
  class?: HTMLAttributes["class"];
}>();

const emits = defineEmits<{
  (e: "update:modelValue", payload: string | number): void;
}>();

// const isBlurred = ref(false);

const { error, isBlurred, submitCount, setIsBlurred } = useFormField();

const modelValue = useVModel(props, "modelValue", emits, {
  passive: true,
  defaultValue: props.defaultValue,
});
</script>

<template>
  <input
    v-model="modelValue"
    @blur="setIsBlurred"
    :class="
      cn({
        'flex w-full rounded-md border-input transition-colors transition-shadow bg-mono-off-white px-6 py-4 text font-semibold ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-mono-light-grey placeholder:font-semibold focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-0 hover:bg-mono-white hover:shadow-inner focus-visible:bg-mono-white focus-visible:shadow-inner disabled:cursor-not-allowed disabled:opacity-50': true,
        'shadow-destructive shadow-inner hover:shadow-destructive focus-visible:ring-ring':
          error && (isBlurred || submitCount),
        [props.class]: true,
      })
    "
  />
</template>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
