<script setup>
import * as ModalContent from "./modalContent";
import { cn } from "@/lib/utils";

const modalStore = useModalStore();
</script>

<template>
  <Dialog v-model:open="modalStore.isOpen">
    <DialogContent
      :size="modalStore.size"
      class="modal-container-ref"
      ref="modal"
    >
      <component
        :is="ModalContent[modalStore.modalKey]"
        v-bind="modalStore.modalProps"
      />
    </DialogContent>
  </Dialog>
</template>

<style>
.slide-enter-active {
  transition:
    opacity 200ms ease-in-out,
    transform 200ms ease-in-out,
    height 200ms ease-in-out,
    width 200ms ease-in-out;
}

.slide-leave-active {
  transition:
    opacity 200ms ease-in-out,
    transform 200ms ease-in-out,
    height 200ms ease-in-out,
    width 200ms ease-in-out;
}

.slide-enter-from,
.slide-leave-to {
  opacity: 0;
}
</style>
