<script setup>
import { toTypedSchema } from "@vee-validate/zod";
import * as z from "zod";
import { useForm } from "vee-validate";

import { useQueryClient } from "@tanstack/vue-query";

const modalStore = useModalStore();
const queryClient = useQueryClient();

const { mutate: createBrand } = useCreateBrandMutation();

const isLoading = ref(false);

const formSchema = toTypedSchema(
  z.object({
    name: z
      .string({ required_error: "dashboard.brand-name-error" })
      .min(1, "dashboard.brand-name-error")
      .max(100),
  }),
);

const form = useForm({
  validationSchema: formSchema,
});

const onSubmit = form.handleSubmit((values) => {
  isLoading.value = true;
  let brandId = null;

  createBrand(values, {
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["brands"],
      });
      modalStore.closeModal();
    },
    onError: (err) => console.error(err),
  });
});
</script>

<template>
  <div class="flex flex-col gap-4">
    <DialogHeader>
      <DialogTitle>
        {{ $t("dashboard.add-new-brand") }}
      </DialogTitle>
      <DialogDescription>
        {{ $t("dashboard.enter-brand-name") }}
      </DialogDescription>
    </DialogHeader>

    <form class="flex flex-col gap-6" @submit="onSubmit">
      <FormField v-slot="{ componentField }" name="name">
        <FormItem>
          <FormControl>
            <Input
              type="text"
              :placeholder="$t('dashboard.enter-brand-name-placeholder')"
              v-bind="componentField"
              maxlength="100"
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      </FormField>
      <Button type="submit" :loading="isLoading" :fullwidth="true" size="lg">
        {{ $t("dashboard.add") }}
      </Button>
    </form>
  </div>
</template>
