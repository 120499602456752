import { useMutation } from "@tanstack/vue-query";

const deleteWine = async ({ wineId, brandId }) => {
  return await fetchWithAuth(
    `/api/currentUser/brands/${brandId}/wines/${wineId}`,
    {
      method: "DELETE",
    }
  );
};

export const useDeleteWineMutation = () => {
  return useMutation({
    mutationFn: deleteWine,
  });
};
