<script setup lang="ts">
import type { HTMLAttributes } from "vue";
import { type NoticeVariants, noticeVariants } from ".";
import { cn } from "@/lib/utils";
import { CloseButton } from "@/components/ui/close-button";

const props = defineProps<{
  class?: HTMLAttributes["class"];
  variant?: NoticeVariants["variant"];
  fullwidth?: boolean;
  actionText?: string;
  actionLoading?: boolean;
}>();
</script>

<template>
  <div
    :class="cn(noticeVariants({ variant, fullwidth }), props.class, 'notice')"
    role="notice"
  >
    <div v-if="$attrs.onDismiss" class="hidden sm:block w-8 h-8"></div>
    <div
      class="flex flex-grow flex-col sm:flex-row items-start sm:items-center justify-center gap-4"
    >
      <span class="w-full md:w-auto text-pretty">
        <slot />
      </span>
      <Button
        variant="primary"
        size="sm"
        v-if="actionText"
        @click="$emit('action')"
        :loading="actionLoading"
        >{{ actionText }}</Button
      >
    </div>

    <CloseButton v-if="$attrs.onDismiss" @click="$emit('dismiss')" />
  </div>
</template>
