import { type VariantProps, cva } from "class-variance-authority";

export { default as Notice } from "./Notice.vue";

export const noticeVariants = cva(
  "flex flex-row items-start sm:items-center justify-between w-full py-4 px-6 text-mono-dark-grey font-medium rounded-[0.5rem] [&>svg~*]:pl-7 [&>svg+div]:translate-y-[-3px] [&>svg]:absolute [&>svg]:left-4 [&>svg]:top-4 [&>svg]:text-foreground",
  {
    variants: {
      variant: {
        default: "text-foreground border-mono-black ",
        primary: "border-primary bg-primary-light",
        warning: "border-warning bg-warning-light",
        destructive:
          "bg-destructive-light border-destructive text-destructive font-semibold",
      },
      fullwidth: {
        false: "w-fit",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  },
);

export type NoticeVariants = VariantProps<typeof noticeVariants>;
