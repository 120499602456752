import { useMutation } from "@tanstack/vue-query";

const deleteBrand = async ({ brandId }) => {
  return await fetchWithAuth(`/api/currentUser/brands/${brandId}`, {
    method: "DELETE",
  });
};

export const useDeleteBrandMutation = () => {
  return useMutation({
    mutationFn: deleteBrand,
  });
};
