import { useMutation } from "@tanstack/vue-query";

const cancelIncompleteSubscription = async (data) => {
  return await fetchWithAuth(`/api/currentUser/cancelIncompleteSubscription`, {
    method: "POST",
    body: data,
  });
};

export const useCancelIncompleteSubscription = () => {
  return useMutation({
    mutationFn: cancelIncompleteSubscription,
  });
};
