<script setup>
import WineForm from "~/components/forms/WineForm.vue";
import { useQueryClient } from "@tanstack/vue-query";
import { useNotification } from "@kyvg/vue3-notification";
import { useForm } from "vee-validate";

import * as z from "zod";
import { toTypedSchema } from "@vee-validate/zod";
import { useI18n } from "vue-i18n";

const { t } = useI18n(); // use as global scope
const { mutate: redirectWine } = useRedirectWineMutation();
const queryClient = useQueryClient();

//set default value for props wine
const props = defineProps({
  wine: {
    type: Object,
    required: true,
  },
  brand: { required: true },
  source: { required: false, default: "unknown" },
  onStarted: { type: Function, required: false },
  onSettled: { type: Function, required: false },
});

const { $intercom } = useNuxtApp();

const loading = ref(false);
const emit = defineEmits();

const isLoading = ref(false);

const { notify } = useNotification();

onMounted(() => {
  $intercom.trackEvent("Label redirect form opened", {
    name: props.wine.name,
    source: props.source,
  });
});

const formSchema = toTypedSchema(
  z.object({
    redirect_to: z
      .string({ required_error: "redirect.url-error" })
      .min(1, "redirect.url-error")
      .url("redirect.url-error"),
  }),
);

const form = useForm({
  validationSchema: formSchema,
});

const modalStore = useModalStore();

const onSubmit = form.handleSubmit((values) => {
  isLoading.value = true;

  if (props.onStarted) {
    props.onStarted();
  }

  redirectWine(
    {
      brandId: props.brand.id,
      wineId: props.wine.id,
      redirect_to: values.redirect_to,
    },
    {
      onSuccess: async () => {
        await Promise.allSettled([
          queryClient.refetchQueries({
            queryKey: ["brands", props.brand.id],
          }),
          queryClient.refetchQueries({
            queryKey: ["user"],
          }),
        ]);
        if (props.onSettled) {
          props.onSettled();
        }
        notify({
          type: "success",
          text: t("redirect.success", [props.wine.name]),
        });
        modalStore.closeModal();
      },
      onError: (err) => {
        console.error(err);
        if (props.onSettled) {
          props.onSettled();
        }
      },
    },
  );
});
</script>

<template>
  <div class="flex flex-col gap-4">
    <DialogHeader>
      <DialogTitle>{{ $t("redirect.title") }}</DialogTitle>
    </DialogHeader>

    <p class="modal-intro">
      {{ $t("redirect.intro") }}
    </p>

    <form class="flex flex-col gap-4" @submit="onSubmit">
      <FormField v-slot="{ componentField }" name="redirect_to">
        <FormItem>
          <FormControl>
            <FormLabel>{{ $t("redirect.label") }}</FormLabel>
            <Input
              type="text"
              placeholder="E.g. https://anotherurl.com/wine-elabel"
              v-bind="componentField"
            />
            <FormDescription>
              {{ $t("redirect.url-desc") }}
            </FormDescription>
          </FormControl>
          <FormMessage />
        </FormItem>
      </FormField>

      <Notice class="text-center mt-4 mb-8 w-full" variant="warning">
        {{ $t("redirect.notice") }}
      </Notice>
      <Button type="submit" :loading="isLoading" :fullwidth="true" size="lg">
        {{ $t("redirect.submit") }}
      </Button>
      <Button
        @click.prevent="modalStore.closeModal"
        :fullwidth="true"
        size="lg"
        variant="secondary-outlined"
      >
        {{ $t("redirect.cancel") }}
      </Button>
      <Text variant="body_light_sm" class="text-center">
        {{ $t("redirect.disclaimer") }}
      </Text>
    </form>
  </div>
</template>
