<script setup>
// props product
import { SubscriptionService } from "~/service/SubscriptionService";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import { useNotification } from "@kyvg/vue3-notification";

const props = defineProps({
  currentPlan: Boolean,
  product: {
    type: Object,
    required: true,
  },
});
const isLoading = ref(false);

const { planLimitDescription } = props.product;

const { currentCurrency } = useCurrencies();

function formatPrice(amount, currency) {
  // Validate the currency code
  console.log({ amount, currency });
  if (!currency || currency.length !== 3) {
    throw new Error("Invalid currency code");
  }

  // Format the amount using the Intl.NumberFormat API
  const formatter = new Intl.NumberFormat("en", {
    currency,
    style: "currency",
  });

  // Return the formatted price
  return formatter.format(amount);
}

const { isFetched, data: userData } = useCurrentUserSubscriptionQuery();

const price = computed(() => {
  if (props.product.prices) {
    const prices = props.product.prices.filter((price) => {
      return price.currency == currentCurrency.value;
    });
    if (prices.length > 0) {
      return prices[0];
    }
  }

  return "";
});

const { mutate: cancelIncompleteSubscription } =
  useCancelIncompleteSubscription();

const subscribe = async (price) => {
  if (props.currentPlan || !isFetched.value) return;

  const { notify } = useNotification();
  //return this.updateSubscription(user, price_id)
  const user = await useCurrentUser();

  console.log(userData.value.hasIncompleteSubscription);
  isLoading.value = true;

  if (userData.value.hasIncompleteSubscription) {
    cancelIncompleteSubscription(
      {},
      {
        onSuccess: () => {
          isLoading.value = true;
          SubscriptionService.getSubscribeLink(price.id);
        },
      },
    );
  } else {
    SubscriptionService.getSubscribeLink(price.id);
  }
};
</script>

<template>
  <loading
    v-model:active="isLoading"
    color="#000000"
    :can-cancel="false"
    :is-full-page="true"
  />

  <tr :class="{ 'price-line': true, 'current-plan': currentPlan }">
    <td class="name">
      {{ $t(planLimitDescription) }}
    </td>
    <td class="price">
      <span v-if="price">
        {{ formatPrice(price.unit_amount / 100, currentCurrency) }}
        <span class="per-year"> / {{ $t("price-line.year") }}</span>
      </span>
      <span v-else>{{ $t("price-line.free") }}</span>
    </td>
    <td class="action">
      <Button
        :fullwidth="true"
        :disabled="currentPlan"
        @click="subscribe(price)"
      >
        {{
          currentPlan ? $t("price-line.current") : $t("price-line.subscribe")
        }}
      </Button>
    </td>
  </tr>
</template>

<style scoped>
td {
  padding: 13px 20px;
}

@media screen and (max-width: 480px) {
  td {
    padding: 13px 5px;
  }
}

td:first-child {
  border-radius: 8px 0 0 8px;
}

td:last-child {
  border-radius: 0 8px 8px 0;
}

.price-line {
  border-radius: 8px;
  outline: 1px solid #dedee4;
}

.current-plan {
  background: #f2f1ff;
  outline: none;
}
.name {
  margin-right: 7px;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  color: #777777;
}

.action {
  text-align: end;
}

.action .button {
  width: 100%;
}
.price {
  font-weight: 600;
  color: #333333;
  width: 160px;
}

.per-year {
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  color: #777777;
}
</style>
