// TODO: Extract this from product prices
const CURRENCIES = ["eur", "nzd", "aud", "usd"];

const currentCurrency = ref();

watch(
  () => currentCurrency.value,
  (val) => {
    localStorage.setItem(`selectedCurrency`, val);
  }
);

export const useCurrencies = () => {
  const { data, isFetched } = useCurrentUserSubscriptionQuery();

  // If currency is not set, check local storage
  if (!currentCurrency.value) {
    currentCurrency.value =
      localStorage.getItem("selectedCurrency") || CURRENCIES[0];
  }

  if (data.value?.currency) {
    currentCurrency.value = data.value.currency;
  }

  watch(
    () => isFetched.value,
    () => {
      if (data.value?.currency) {
        currentCurrency.value = data.value.currency;
      }
    }
  );

  return {
    currentCurrency: currentCurrency,
    currencies: CURRENCIES,
    currencyLocked: !!data.value?.currency,
  };
};
