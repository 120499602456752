import { useMutation } from "@tanstack/vue-query";

const unpublishWine = async ({ brandId, wineId }) => {
  const res = await fetchWithAuth(
    `/api/currentUser/brands/${brandId}/wines/${wineId}/unpublishWine`,
    {
      method: "POST",
    }
  );
  console.log("Wine Unpublished!");
  return res;
};

export const useUnpublishWineMutation = () => {
  return useMutation({
    mutationFn: unpublishWine,
  });
};
