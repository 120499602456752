export const useIngredientTranslation = () => {
  const { data, isFetched } = useIngredientsTranslationsQuery();

  const { locale } = useI18n();

  const getIngredientTranslation = (key) => {
    if (!isFetched.value || !data.value) {
      return "";
    }

    const ingredient = data.value[key];

    if (ingredient) {
      return ingredient[locale.value] || "";
    }

    return "";
  };

  return {
    isIngredientTranslationsFetched: isFetched,
    getIngredientTranslation,
  };
};
