<script setup>
import { useQueryClient } from "@tanstack/vue-query";
import { useNotification } from "@kyvg/vue3-notification";
import { useI18n } from "vue-i18n";

const { t } = useI18n(); // use as global scope

const { mutate: updateWine } = useUpdateWineMutation();
const queryClient = useQueryClient();

//set default value for props wine
const props = defineProps({
  wine: {
    type: Object,
    required: true,
  },
  brand: { required: true },
  source: { required: false, default: "unknown" },
  onStarted: { type: Function, required: false },
  onSettled: { type: Function, required: false },
});

const { $intercom } = useNuxtApp();

const emit = defineEmits();

const isLoading = ref(false);

const { notify } = useNotification();

onMounted(() => {
  $intercom.trackEvent("Remove label redirect form opened", {
    name: props.wine.name,
    source: props.source,
  });
});

const modalStore = useModalStore();

const removeRedirect = () => {
  isLoading.value = true;

  if (props.onStarted) {
    props.onStarted();
  }

  updateWine(
    {
      brandId: props.brand.id,
      wineId: props.wine.id,
      redirect_to: "",
    },
    {
      onSuccess: async () => {
        await Promise.allSettled([
          queryClient.refetchQueries(["brands"]),
          queryClient.refetchQueries({ queryKey: ["user"] }),
        ]);
        modalStore.closeModal();
        if (props.onSettled) {
          props.onSettled();
        }
        notify({
          type: "success",
          text: t("remove-redirect-modal.success", [props.wine.name]),
        });
      },
      onError: (err) => {
        console.error(err);
        if (props.onSettled) {
          props.onSettled();
        }
      },
    },
  );
};
</script>

<template>
  <div class="flex flex-col gap-4">
    <DialogHeader>
      <DialogTitle>{{ $t("remove-redirect-modal.title") }}</DialogTitle>
    </DialogHeader>

    <Text variant="body_light" class="mb-6">
      {{ $t("remove-redirect-modal.description") }}
    </Text>

    <Button
      @click="removeRedirect"
      variant="secondary"
      :loading="isLoading"
      :fullwidth="true"
      size="lg"
    >
      {{ $t("remove-redirect-modal.action") }}
    </Button>
    <Button
      @click.prevent="modalStore.closeModal"
      :fullwidth="true"
      size="lg"
      variant="secondary-outlined"
    >
      {{ $t("remove-redirect-modal.cancel") }}
    </Button>
    <Text variant="body_light_sm" class="text-center">
      {{ $t("remove-redirect-modal.disclaimer") }}
      <TextLink :external="true" :href="wine.redirect_to">{{
        wine.redirect_to
      }}</TextLink>
    </Text>
  </div>
</template>
