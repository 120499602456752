import { useMutation } from "@tanstack/vue-query";

const createBrand = async (brandData) => {
  console.log("Call create user!");
  return await fetchWithAuth("/api/currentUser/brands", {
    method: "POST",
    body: { brandData },
  });
};

export const useCreateBrandMutation = () => {
  return useMutation({
    mutationFn: createBrand,
  });
};
