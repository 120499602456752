<script lang="ts" setup>
import { ErrorMessage } from "vee-validate";
import { toValue } from "vue";
import { useFormField } from "./useFormField";

const { error, name, formMessageId, isBlurred, submitCount } = useFormField();
</script>

<template>
  <Transition appear>
    <div
      class="wrapper mt-[-0.75rem]"
      v-if="error && (submitCount || isBlurred)"
    >
      <div class="overflow-hidden">
        <ErrorMessage
          :id="formMessageId"
          as="p"
          :name="toValue(name)"
          class="text-sm font-semibold text-destructive overflow-hidden pt-3"
        >
          {{ $t(error) }}
        </ErrorMessage>
      </div>
    </div>
  </Transition>
</template>

<style scoped>
.wrapper {
  display: grid;
  transition:
    grid-template-rows 0.15s,
    opacity 0.15s 0.1s,
    transform 0.15s 0.1s;

  grid-template-rows: 1fr;
  transform: 0;
  opacity: 1;
}

/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
}

.wrapper.v-enter-from,
.wrapper.v-leave-to {
  grid-template-rows: 0fr;
  transform: translateY(-100%);
  opacity: 0;
}
</style>
