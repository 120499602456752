<script setup>
const emit = defineEmits();
const props = defineProps({
  options: {
    type: Array,
    required: true,
    default: () => [],
  },
  selected: {
    type: Array,
    required: false,
    default: () => [],
  },
});

// emit after render
onMounted(() => {
  emit("update", refValues.value);
});

const refValues = ref(props.selected);

function toggleSelection(value) {
  const values = refValues.value;
  if (values.includes(value)) {
    // Deselect the button if it's already selected
    const index = values.indexOf(value);
    values.splice(index, 1);
  } else {
    // Select the button if it's not selected
    values.push(value);
  }
  refValues.value = values;

  emit("update", refValues.value);
}
</script>

<template>
  <div>
    <button
      v-for="option in options"
      type="button"
      class="ring-offset-background focus-visible:ring-2 focus-visible:ring-ring custom-button transition-all"
      @click.prevent="toggleSelection(option.value)"
      :class="{ selected: refValues.includes(option.value) }"
    >
      {{ option.name }}
    </button>
  </div>
</template>

<style scoped>
/* Style the custom button */
.custom-button {
  border-radius: 6px;
  margin: 5px;
  display: inline-block;
  padding: 10px 20px;
  border: 2px solid #757484;
  background-color: white;
  color: black;
  cursor: pointer;
  outline: none;
}

/* Style the selected state of the custom button */
.custom-button.selected {
  background: #181649;
  border: 2px solid #181649;
  color: white;
}
</style>
