export const getLocales = () => [
  {
    value: "en",
    name: "English",
    icon: "fi fi-us",
  },
  {
    value: "fr",
    name: "Français",
    icon: "fi fi-fr",
  },
  {
    value: "bg",
    name: "Български",
    icon: "fi fi-bg",
  },
  {
    value: "hr",
    name: "Hrvatski",
    icon: "fi fi-hr",
  },
  {
    value: "cs",
    name: "čeština",
    icon: "fi fi-cz",
  },
  {
    value: "da",
    name: "Dansk",
    icon: "fi fi-dk",
  },
  {
    value: "nl",
    name: "Nederlands",
    icon: "fi fi-nl",
  },
  {
    value: "et",
    name: "Eesti keel",
    icon: "fi fi-ee",
  },
  {
    value: "fi",
    name: "Suomi",
    icon: "fi fi-fi",
  },
  {
    value: "de",
    name: "Deutsch",
    icon: "fi fi-de",
  },
  {
    value: "el",
    name: "ελληνικά ",
    icon: "fi fi-gr",
  },
  {
    value: "hu",
    name: "Magyar",
    icon: "fi fi-hu",
  },
  {
    value: "ga",
    name: "Gaeilge",
    icon: "fi fi-ie",
  },
  {
    value: "it",
    name: "Italiano",
    icon: "fi fi-it",
  },
  {
    value: "lv",
    name: "Latviski",
    icon: "fi fi-lv",
  },
  {
    value: "lt",
    name: "Lietuvių",
    icon: "fi fi-lt",
  },
  {
    value: "mt",
    name: "Malti",
    icon: "fi fi-mt",
  },
  {
    value: "pl",
    name: "Polski",
    icon: "fi fi-pl",
  },
  {
    value: "pt",
    name: "Português",
    icon: "fi fi-pt",
  },
  {
    value: "ro",
    name: "Română",
    icon: "fi fi-ro",
  },
  {
    value: "sk",
    name: "Slovenský",
    icon: "fi fi-sk",
  },
  {
    value: "sl",
    name: "Slovenščina",
    icon: "fi fi-si",
  },
  {
    value: "es",
    name: "Español",
    icon: "fi fi-es",
  },
  {
    value: "sv",
    name: "Svenska",
    icon: "fi fi-se",
  },
];

export const getNameForLocale = (locale) =>
  console.log(locale) || getLocales().find((l) => l.value == locale)?.name;
