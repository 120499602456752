import { useQuery, useQueryClient } from "@tanstack/vue-query";

const fetchProducts = async () => {
  return await fetchWithAuth("/api/products");
};

export const useProductsQuery = () => {
  return useQuery({
    queryKey: ["products"],
    queryFn: fetchProducts,
    refetchOnMount: false,
  });
};

export const useProductsPrefetch = () => {
  const queryClient = useQueryClient();

  return () => {
    queryClient.prefetchQuery({
      queryKey: ["products"],
      queryFn: fetchProducts,
      staleTime: 600000, // 10 minutes
    });
  };
};
