export const useRecyclingTranslation = () => {
  const { data, isFetched } = useRecyclingTranslationsQuery();

  const { locale } = useI18n();

  const getRecyclingTranslation = (type, key) => {
    if (!isFetched.value) {
      return "";
    }

    console.log(data.value[type][key][locale.value]);

    return data.value[type][key][locale.value];
  };

  return {
    isRecyclingTranslationsFetched: isFetched,
    getRecyclingTranslation,
  };
};
