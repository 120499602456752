<script setup>
// See https://vueuse.org/core/useFileDialog
import { useFileDialog } from "@vueuse/core";
import {
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { useFirebaseStorage, useStorageFile } from "vuefire";

const props = defineProps({
  prefix: {
    type: String,
    required: true,
  },
  image: {
    type: String,
  },
});

const imageId = ref("");
const url = ref("");
const isUploading = ref(false);

const storage = useFirebaseStorage();

const emit = defineEmits(["update:modelValue"]);

const { files, open, reset, onChange } = useFileDialog();

watch(files, async () => {
  let res = "";
  for (let i = 0; i < 16; i++) {
    // Generate a random number between 0 and 16.
    let randomNumber = Math.floor(Math.random() * 20);
    // Convert the random number to a hexadecimal string.
    let hexString = randomNumber.toString(16);
    // Return the hexadecimal string.
    res += hexString;
  }

  console.log("Files changed, set imageId");
  imageId.value = res;

  const fileStorageRef = storageRef(
    storage,
    props.prefix + imageId.value + ".jpg",
  );

  isUploading.value = true;

  const uploadResult = await uploadBytes(fileStorageRef, files.value?.item(0));

  console.log(uploadResult);

  const downloadUrl = await getDownloadURL(uploadResult.ref);

  isUploading.value = false;

  url.value = downloadUrl;
});

watch(url, (url) => {
  console.log(url);
  if (url) {
    console.log(url);
    emit("update:modelValue", { url, imageId: imageId.value });
  }
});
</script>

<template>
  <div class="img-uploader">
    <div class="no-image text-center" v-if="!files && !props.image">
      <font-awesome icon="cloud-upload" size="2x" class="grey text-center" />
      <div class="font-bold mt-3 mb-5">
        {{ $t("image-upload.prompt") }}
      </div>
      <!-- disable the form while uploading -->
      <fieldset :disabled="isUploading">
        <Button
          variant="secondary"
          type="button"
          @click.prevent="open({ accept: 'image/*', multiple: false })"
        >
          <font-awesome icon="cloud-upload" class="text-white mr-2" />
          {{ $t("image-upload.choose-image") }}
        </Button>
      </fieldset>
    </div>
    <div v-else>
      <div class="preview flex items-center justify-center" v-if="!!uploadTask">
        <font-awesome icon="spinner" size="3x" spin />
      </div>
      <img class="preview" v-else-if="files" :src="url" />
      <img class="preview" v-else-if="props.image" :src="props.image" />
      <!-- disable the form while uploading -->
      <fieldset :disabled="!!uploadTask" class="grey">
        <div class="flex items-center justify-center">
          <Button
            variant="secondary-outlined"
            type="button"
            @click.prevent="open({ accept: 'image/*', multiple: false })"
          >
            <font-awesome icon="cloud-upload" class="grey mr-2" />
            {{ $t("image-upload.replace-image") }}
          </Button>
        </div>
      </fieldset>
    </div>
  </div>
</template>

<style scoped>
.preview {
  height: 180px;
  object-fit: cover;
  margin: 0 auto;
}
.img-uploader {
  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 300px;
    background: #f7f7f7;
    border-radius: 25px;
  }
  .grey {
    color: #757484;
  }
}
</style>
