<script setup>
import { useI18n } from "vue-i18n";
import RecyclingLogo from "./RecyclingLogo";

const { locale } = useI18n();

const {
  getMaterialFromId,
  getColorForRecyclingMethod,
  isRecyclingDataFetched,
} = useRecyclingData();
const { getRecyclingTranslation, isRecyclingTranslationsFetched } =
  useRecyclingTranslation();

const props = defineProps({
  type: {
    type: String,
    required: true,
  },
  material: {
    type: String,
    required: true,
  },
  recyclingMethod: {
    type: String,
    required: true,
  },
  greyBackground: {
    type: Boolean,
    default: false,
  },
});

const materialData = computed(() => getMaterialFromId(props.material));

const color = computed(() => getColorForRecyclingMethod(props.recyclingMethod));
</script>

<template>
  <div
    v-if="isRecyclingDataFetched && isRecyclingTranslationsFetched"
    :class="{
      'p-4 rounded-lg flex flex-row gap-4 w-full': true,
      'bg-mono-off-white': greyBackground,
      'bg-mono-white': !greyBackground,
    }"
  >
    <div
      class="rounded-full w-[6px]"
      :style="{
        background: color,
      }"
    ></div>
    <div class="flex flex-col items-center gap-2">
      <RecyclingLogo :color="color" :code="materialData.recyclingCode" />
      <span class="font-bold leading-none text-mono-dark-grey">{{
        materialData.abbreviation
      }}</span>
    </div>
    <div class="flex flex-col justify-center">
      <span class="font-bold capitalize text-lg" v-if="type">
        {{ getRecyclingTranslation("packagingTypes", type) }}
      </span>
      <span class="font-bold capitalize" v-if="material">
        {{ getRecyclingTranslation("materials", material) }}
      </span>
      <span class="text-mono-grey" v-if="recyclingMethod">{{
        getRecyclingTranslation("recyclingMethods", recyclingMethod)
      }}</span>
    </div>
  </div>
</template>

<style scoped></style>
