const carbo_kj = 17
const carbo_kcal = 4
const polyols_kj = 10
const polyols_kcal = 2.4
const protein_kj = 17
const protein_kcal = 4
const fat_kj = 37
const fat_kcal = 9
const salatrims_kj = 25
const salatrims_kcal = 6
const alcohol_kj = 29
const alcohol_kcal = 7
const organic_acid_kj = 13
const organic_acid_kcal = 4
const fibre_kj = 8
const fibre_kcal = 2
const erythritol_kj = 0
const erythritol_kcal = 0

export const EnergyService = {
  calculateEnergyKcal(alcohol, sugar, acid, polyols) {
    if (alcohol === '' || sugar === '' || acid === '' || polyols === '') {
      return ''
    }
    const res = Math.ceil(
      (alcohol * alcohol_kcal * 100 * 0.789 +
        sugar * carbo_kcal +
        acid * organic_acid_kcal +
        polyols * polyols_kcal) /
        100,
    )
    // empty if nan
    if (isNaN(res)) {
      return ''
    }
    return res
  },

  calculateEnergyKj(alcohol, sugar, acid, polyols) {
    if (alcohol === '' || sugar === '' || acid === '' || polyols === '') {
      return ''
    }
    const res = Math.ceil(
      (alcohol * alcohol_kj * 100 * 0.789 +
        sugar * carbo_kj +
        acid * organic_acid_kj +
        polyols * polyols_kj) /
        100,
    )
    // empty if nan
    if (isNaN(res)) {
      return ''
    }
    return res
  },
}
