<script lang="ts" setup>
import type { HTMLAttributes } from "vue";
import type { LabelProps } from "radix-vue";
import { useFormField } from "./useFormField";
import { cn } from "@/lib/utils";
import { Label } from "@/components/ui/label";

const props = defineProps<LabelProps & { class?: HTMLAttributes["class"] }>();

const { error, formItemId, isBlurred, submitCount } = useFormField();
</script>

<template>
  <Label
    :class="
      cn(error && (isBlurred || submitCount) && 'text-destructive', props.class)
    "
    :for="formItemId"
  >
    <slot />
  </Label>
</template>
