import { useFirestore } from "vuefire";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import axios from "axios";

export const SubscriptionService = {
  getSubscription(user) {
    return new Promise((resolve, reject) => {
      const db = useFirestore();
      const subscriptionCol = query(
        collection(db, "customers/" + user.value?.uid + "/subscriptions"),
        where("status", "==", "active")
      );
      getDocs(subscriptionCol)
        .then((docSnapshot) => {
          if (docSnapshot.empty) {
            reject("No subscription found");
          }
          const subscriptions = [];
          docSnapshot.forEach((doc) => {
            this.getProductFromSubscription(doc.data())
              .then((product) => {
                const d = doc.data();
                delete d.product;
                var sub = { id: doc.id, product: product, ...d };
                resolve(sub);
              })
              .catch(() => {
                resolve(null);
              });
          });
        })
        .catch(reject);
    });
  },

  getProductFromSubscription(subscription) {
    return new Promise((resolve, reject) => {
      const db = useFirestore();
      const productDoc = subscription.product;
      getDoc(productDoc)
        .then((docSnapshot) => {
          resolve({ id: docSnapshot.id, ...docSnapshot.data() });
        })
        .catch(reject);
    });
  },

  async getPortalUrl() {
    const user = useCurrentUser();
    const token = user.value.accessToken;
    const instance = axios.create({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const config = useRuntimeConfig();
    const res = await instance.get(config.public.API_BASE_URL + "/portal_url");
    return res.data;
  },

  async updateSubscription(user, price_id) {
    const token = user.value.accessToken;
    const instance = axios.create({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const config = useRuntimeConfig();
    const res = await instance.put(
      config.public.API_BASE_URL + "/user/subscription",
      {
        newPrice: price_id,
      }
    );
  },

  async getSubscribeLink(price_id) {
    const user = await useCurrentUser();
    const db = useFirestore();

    const docRef = await addDoc(
      collection(db, "customers/" + user.value?.uid + "/checkout_sessions"),
      {
        automatic_tax: true, // Automatically calculate tax based on the customer's address
        tax_id_collection: true, // Collect the customer's tax ID (important for B2B transactions)
        price: price_id,
        success_url: `${window.location.origin}/account?subscribe_success=1`,
        cancel_url: `${window.location.origin}/account`,
        allow_promotion_codes: true,
      }
    );

    // Wait for the CheckoutSession to get attached by the extension
    onSnapshot(docRef, (snap) => {
      const { error, url } = snap.data();
      if (error) {
        // Show an error to your customer and
        // inspect your Cloud Function logs in the Firebase console.
        console.log(`An error occured: ${error.message}`);
        console.log(error);
      }
      if (url) {
        // We have a Stripe Checkout URL, let's redirect.
        window.location.assign(url);
      }
    });
  },
};
