<script setup>
import NewSubscriptionForm from "~/components/forms/NewSubscriptionForm.vue";
import UpdateSubscriptionForm from "~/components/forms/UpdateSubscriptionForm.vue";

const props = defineProps({
  source: { type: String, default: "unknown" },
});

const { isLoading, data } = useCurrentUserSubscriptionQuery();

const modalStore = useModalStore();
</script>

<template>
  <div class="flex flex-col gap-4">
    <h1 v-if="isLoading">Loading</h1>

    <UpdateSubscriptionForm
      :source="source"
      @confirm="modalStore.closeModal"
      v-else-if="data.isSubscribed"
    />

    <NewSubscriptionForm :source="source" v-else />
  </div>
</template>
