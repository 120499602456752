export const useRecyclingData = () => {
  const { data: recyclingMap, isFetched: isRecyclingMapFetched } =
    useRecyclingMapQuery();

  const { getRecyclingTranslation, isRecyclingTranslationsFetched } =
    useRecyclingTranslation();

  const isFetched = computed(
    () => isRecyclingMapFetched.value && isRecyclingTranslationsFetched.value
  );

  const packagingTypes = computed(
    () => recyclingMap.value?.packagingTypes || []
  );
  const materials = computed(() => recyclingMap.value?.materials || []);
  const materialCategories = computed(
    () => recyclingMap.value?.materialCategories || []
  );
  const recyclingMethods = computed(
    () => recyclingMap.value?.recyclingMethods || []
  );

  const defaultRecyclingRow = {
    type: "bottle",
    category: "glass",
    material: "green_glass",
    recyclingMethod: "glass_collection",
  };

  const getMaterialCategoryFromId = (id) =>
    materialCategories.value.find((cat) => cat.id === id);

  const getMaterialFromId = (id) => materials.value.find((m) => m.id == id);

  const getSimpleMaterialsFromCategoryId = (categoryId) =>
    materials.value
      .filter(
        (material) =>
          material.materialCategory === categoryId && !material.isComposite
      )
      .sort((a, b) => {
        const codeCompare = a.recyclingCode.localeCompare(b.recyclingCode);

        if (!a.recyclingCode) return 10;
        if (!b.recyclingCode) return -10;

        if (codeCompare === 0) {
          return a.abbreviation.localeCompare(b.abbreviation);
        }

        return codeCompare;
      });

  const getCompositeMaterialsFromCategoryId = (categoryId) =>
    materials.value
      .filter(
        (material) =>
          material.materialCategory === categoryId && material.isComposite
      )
      .sort((a, b) => {
        const codeCompare = a.recyclingCode.localeCompare(b.recyclingCode);

        if (!a.recyclingCode) return 10;
        if (!b.recyclingCode) return -10;

        if (codeCompare === 0) {
          return a.abbreviation.localeCompare(b.abbreviation);
        }

        return codeCompare;
      });

  const getFirstMaterialFromCategory = (category) =>
    (
      getSimpleMaterialsFromCategoryId(category.id)[0] ||
      getCompositeMaterialsFromCategoryId(category.id)[0]
    ).id;

  const getFirstMaterialFromCategoryId = (id) => {
    const material = getMaterialCategoryFromId(id);

    return getFirstMaterialFromCategory(material);
  };

  const getColorForRecyclingMethod = (recyclingMethod) => {
    return (
      recyclingMap.value?.recyclingMethodsColors[recyclingMethod] || "#999"
    );
  };

  return {
    isRecyclingDataFetched: isFetched,

    packagingTypes,
    materials,
    materialCategories,
    recyclingMethods,

    getMaterialFromId,

    getSimpleMaterialsFromCategoryId,
    getCompositeMaterialsFromCategoryId,

    getFirstMaterialFromCategory,
    getFirstMaterialFromCategoryId,

    getColorForRecyclingMethod,

    defaultRecyclingRow,
  };
};
